import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { traits } from '../../../config/traits';

const splitArray = (array: string[]) => {
  const half = Math.ceil(array.length / 2);
  const firstHalf = array.slice(0, half);
  const secondHalf = array.slice(-half);
  return [firstHalf, secondHalf];
};

const DestinationPII = ({ formik }) => {
  const [firstColTraits, secondColTraits] = splitArray(traits); // split the array of traits into 2 columns

  return (
    <>
      <Box marginBottom={2}>
        <Typography variant='h4' style={{ flex: 1 }}>
          Allowed PII
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant='subtitle1'>
          This step is optional. Below is a list of user traits that were
          collected by this beacon. User traits are known as personally
          identifiable information (e.g. a person’s name, email, number). Check
          the boxes next to the PII events that should be marked as ‘Allowed’
          when sending activity to this destination (e.g. credit card
          information):
        </Typography>
      </Box>
      <Grid container>
        <Grid container item direction='column' xs={6}>
          {firstColTraits?.map((trait, index) => (
            <FormControlLabel
              key={index}
              label={trait}
              control={
                <Checkbox
                  name='allowedPII'
                  value={trait}
                  checked={formik.values.allowedPII.includes(trait)}
                  onChange={formik.handleChange}
                />
              }
            />
          ))}
        </Grid>
        <Grid container item direction='column' xs={6}>
          {secondColTraits?.map((trait, index) => (
            <FormControlLabel
              key={index}
              label={trait}
              control={
                <Checkbox
                  name='allowedPII'
                  value={trait}
                  checked={formik.values.allowedPII.includes(trait)}
                  onChange={formik.handleChange}
                />
              }
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default DestinationPII;
