import { AccordionDetails } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  DataLayerTrackerForm,
  setDataLayerOnSubmit,
} from './tracker-components/DataLayerTrackerComponent';
import {
  FBTrackerForm,
  setFBPixelOnSubmit,
} from './tracker-components/FacebookTrackerComponent';
import {
  OneTrustTrackerForm,
  setOneTrustOnSubmit,
} from './tracker-components/OneTrustTrackerComponent';
import {
  PageTrackerForm,
  setPageOnSubmit,
} from './tracker-components/PageTrackerComponent';
import {
  SessionTrackerForm,
  setSessionOnSubmit,
} from './tracker-components/SessionTrackerComponent';
import {
  setUserOnSubmit,
  UserTrackerForm,
} from './tracker-components/UserTrackerComponent';
import {
  beaconadvancedDropDown,
  TrackerAccordian,
  trackerFormDefaultValues,
  TrackerFormFields,
} from './TrackerConstants';

const defaultValues = trackerFormDefaultValues;
const TrackerSelectorForm = ({
  id,
  onFormChange,
  form_data,
  beacon,
}: TrackerAccordian) => {
  const methods = useForm<TrackerFormFields>({ defaultValues: form_data });
  const { control, setValue, getValues } = methods;
  const advancedDropDown = beaconadvancedDropDown;

  const onSubmit = () => {
    const updatedData = getValues();
    if (id === 'user') {
      setUserOnSubmit(form_data, updatedData);
    }
    if (id === 'dataLayer') {
      setDataLayerOnSubmit(form_data, updatedData);
    }
    if (id === 'oneTrust') {
      setOneTrustOnSubmit(form_data, updatedData);
    }
    if (id === 'session') {
      setSessionOnSubmit(form_data, updatedData);
    }
    if (id === 'fbPixel') {
      setFBPixelOnSubmit(form_data, updatedData);
    }
    if (id === 'page') {
      setPageOnSubmit(form_data, updatedData);
    }
    onFormChange(form_data, id);
  };

  const FormPicker = () => {
    switch (id) {
      case 'user':
        return UserTrackerForm(control, setValue, onSubmit, form_data);
      case 'dataLayer':
        return DataLayerTrackerForm(
          control,
          setValue,
          onSubmit,
          form_data,
          beacon
        );
      case 'oneTrust':
        return OneTrustTrackerForm(control, setValue, onSubmit, form_data);
      case 'session':
        return SessionTrackerForm(control, setValue, onSubmit);
      case 'fbPixel':
        return FBTrackerForm(control, setValue, onSubmit, form_data);
      case 'page':
        return PageTrackerForm(control, setValue, onSubmit, form_data);
      default:
        return (
          <>
            <p>This tracker currently has no customization settings</p>
          </>
        );
    }
  };

  return (
    <>
      <AccordionDetails>
        <FormPicker></FormPicker>
      </AccordionDetails>
    </>
  );
};

export default TrackerSelectorForm;
