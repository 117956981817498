import { Grid, Typography } from '@mui/material';
import { AdminToolTip } from '../../../../../components/AdminToolTip';
import { FormInputText } from '../../form-components/FormInputText';
import { DefaultBeaconSettingOptions } from '../BeaconSettingConstants';
export const setActivityPreviousConfig = (savedData, name) => {
  const activitySettings = savedData.activity;
  const form_data_obj: any = {
    ...DefaultBeaconSettingOptions.find((element) => {
      return element.id === name;
    })?.form_data,
  };
  if (activitySettings.devQueueMaxSize) {
    form_data_obj.devQueueMaxSize = activitySettings.devQueueMaxSize;
  }
  return form_data_obj;
};

export const userActivitySetting = (form_data) => {
  if (form_data.devQueueMaxSize === '') {
    return null;
  } else {
    return { devQueueMaxSize: form_data.devQueueMaxSize };
  }
};

export const setActivitySubmit = (form_data, updatedData) => {
  form_data.devQueueMaxSize = updatedData.devQueueMaxSize;
};

export const ActivitySettingForm = (control, setValue, onSubmit, form_data) => {
  return (
    <Grid container spacing={1}>
      <p style={{ marginTop: 0, marginBottom: 0 }}>
        Devque Max Size{' '}
        <AdminToolTip
          message={
            <Typography variant='caption'>
              placeholder text for what we want to display for info
            </Typography>
          }
        ></AdminToolTip>
      </p>
      <Grid item spacing={1} sm={12}>
        <FormInputText
          name='devQueueMaxSize'
          control={control}
          label='Maximum queue items'
          defaultValue={form_data.devQueueMaxSize}
          setValue={setValue}
          submitfunction={onSubmit}
          errorCheck={'integerCheck'}
          testId={'activity-input'}
        />
      </Grid>
    </Grid>
  );
};
export {};
