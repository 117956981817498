import AdminPage from '../../components/AdminPage';
import React from 'react';
import { useParams } from 'react-router-dom';
import UserProfileTemplate from '../../components/templates/UserProfile';
// import ActivityTemplate from '../../components/templates/users/ActivityTemplate';
// import NotificationsTemplate from '../../components/templates/users/NotificationsTemplate';

const UserDetail = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      {id && (
        <AdminPage title={`User Detail`}>
          <UserProfileTemplate id={id} />
        </AdminPage>
      )}
    </>
  );
};

export default UserDetail;
