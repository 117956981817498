import { roles } from '../config/roles';

export const schema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      title: 'Group Name',
      description: 'The name of the admin group',
    },
    description: {
      type: 'string',
      title: 'Description',
      description:
        'The description of the group that will be helpful to understand its purpose for other administrators',
    },
    roles: {
      type: 'array',
      title: 'Roles',
      items: {
        title: 'Role',
        type: 'string',
        anyOf: roles,
      },
      description: 'Roles to be applied to this admin group',
    },
  },
};
