import MonacoEditor from '@monaco-editor/react';
import { Link as UiLink, Table, TableBody, TableCell } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import { AdminTableHeadCell, AdminTableRow } from '../../components/AdminTable';

const ProspectDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { jwtToken, adminUserApi } = useBlueprintAdmin();
  const [record, setRecord] = useState<any>(undefined);

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    if (id) {
      adminUserApi
        ?.getProspect(id)
        .then(({ data }) => {
          setRecord(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [id, jwtToken]);

  return (
    <>
      {id && record && (
        <AdminPage animation={true} title={record.external_id}>
          <AdminCard>
            <Table>
              <TableBody>
                <AdminTableRow key={0}>
                  <AdminTableHeadCell>External Id</AdminTableHeadCell>
                  <TableCell>{record.external_id}</TableCell>
                </AdminTableRow>
                <AdminTableRow key={1}>
                  <AdminTableHeadCell>External Source</AdminTableHeadCell>
                  <TableCell>{record.external_source}</TableCell>
                </AdminTableRow>
                <AdminTableRow key={2}>
                  <AdminTableHeadCell>Created At</AdminTableHeadCell>
                  <TableCell>{record.createdAt}</TableCell>
                </AdminTableRow>
                <AdminTableRow key={3}>
                  <AdminTableHeadCell>Updated At</AdminTableHeadCell>
                  <TableCell>{record.updatedAt}</TableCell>
                </AdminTableRow>
                {record.userId === 'false' && (
                  <AdminTableRow key={4}>
                    <AdminTableHeadCell>User ID</AdminTableHeadCell>
                    <TableCell>Not Matched</TableCell>
                  </AdminTableRow>
                )}
                {record.userId != 'false' && (
                  <AdminTableRow key={4}>
                    <AdminTableHeadCell>User ID</AdminTableHeadCell>
                    <TableCell>
                      <UiLink component={Link} to={`/users/${record.userId}`}>
                        {record.userId}
                      </UiLink>
                    </TableCell>
                  </AdminTableRow>
                )}

                {record.entityType && (
                  <AdminTableRow key={5}>
                    <AdminTableHeadCell>Entity Type</AdminTableHeadCell>
                    <TableCell>{record.entityType}</TableCell>
                  </AdminTableRow>
                )}
                {record.entityId && (
                  <AdminTableRow key={6}>
                    <AdminTableHeadCell>Entity Type</AdminTableHeadCell>
                    <TableCell>{record.entityId}</TableCell>
                  </AdminTableRow>
                )}
                <AdminTableRow key={7}>
                  <AdminTableHeadCell>Record</AdminTableHeadCell>
                  <TableCell>
                    <MonacoEditor
                      theme='vs-light'
                      defaultLanguage='json'
                      value={JSON.stringify(record.record, null, 2)}
                      height='200px'
                      width='100%'
                      options={{
                        minimap: {
                          enabled: false,
                        },
                        automaticLayout: true,
                      }}
                    />
                  </TableCell>
                </AdminTableRow>
              </TableBody>
            </Table>
          </AdminCard>
        </AdminPage>
      )}
    </>
  );
};

export default ProspectDetail;
