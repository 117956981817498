export const uiSchema = {
  config: {
    'ui:widget': 'jsonField',
    'ui:emptyValue': '',
  },
};

export const schema = {
  properties: {
    name: { type: 'string' },
    cron: {
      type: 'string',
      description:
        '(Requires either cron or interval) Cron syntax used to run the job. You can use https://crontab.guru/ if it helps!',
    },
    interval: {
      type: 'string',
      description:
        '(Requires either cron or interval) This supports later, human-interval, or ms strings.',
    },
    job: {
      type: 'string',
      description:
        'The name of the job to run, which is store within cron-service/jobs',
    },
    config: {
      type: 'string',
      description: 'A JSON scheme config to use for running the cron job',
    },
    status: {
      title: 'Status',
      enum: ['active', 'paused', 'inactive'],
      description:
        'If active, the job will run as scheduled, if paused the job will register but not run. If the job is inactive, it will neither schedule or run.',
    },
  },
  type: 'object',
  required: ['name', 'job', 'status'],
};
