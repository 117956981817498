import { Grid, Typography } from '@mui/material';
import SVG from 'react-inlinesvg';
import AdminPage from '../components/AdminPage';
import Register from './auth/register';

const Welcome = () => {
  return (
    <>
      <Grid container>
        <Grid container style={{ marginLeft: '20px', marginTop: '20px' }}>
          <Grid item>
            <SVG src={'/assets/images/logo.svg'}></SVG>
          </Grid>
          <Grid item>
            <Typography
              variant='h5'
              style={{
                fontSize: '35px',
                marginTop: '0px',
                paddingTop: '0px',
                paddingLeft: '16px',
              }}
            >
              Blueprint
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AdminPage showSidebar={false}>
            <Grid container justifyContent='center'>
              <Grid item xs={8}>
                <Grid container justifyContent='center'>
                  <Typography
                    variant='h3'
                    align='center'
                    style={{ marginBottom: '20px' }}
                  >
                    {' '}
                    Welcome to Blueprint’s Admin Panel
                  </Typography>
                  <Typography
                    variant='h6'
                    align='center'
                    style={{ width: '80%' }}
                  >
                    {' '}
                    This is where you can perform administrative actions to
                    adjust Blueprint’s behavior when collecting and storing
                    first-party data. It is highly recommended to review our
                    documentation before setting up Blueprint.{' '}
                  </Typography>
                </Grid>
                <Register />
              </Grid>
            </Grid>
          </AdminPage>
        </Grid>
      </Grid>
    </>
  );
};

export default Welcome;
