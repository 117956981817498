import MeiliSearch from 'meilisearch';
import getEnv from './getEnv';

const searchClient = new MeiliSearch({
  host:
    getEnv('REACT_APP_SEARCH_API_DOMAIN') ??
    'https://search.davidsa5.sandbox.ddstudios.us',
  apiKey: getEnv('REACT_APP_SEARCH_API_KEY') ?? '', // if meilisearch was not configured correctly - force a 401 error
});

export default searchClient;
