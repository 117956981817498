import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { AdminToolTip } from '../../../../components/AdminToolTip';
interface FormInputProps {
  name: string;
  control: any;
  label: string;
  setValue: any;
  optionsList?: any;
  defaultValue?: any;
  submitfunction?: any;
  toolTip?: any;
}

export const FormInputRadio: React.FC<FormInputProps> = ({
  name,
  control,
  label,
  optionsList,
  setValue,
  defaultValue,
  submitfunction,
  toolTip,
}) => {
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [defaultRun, setDefaultRun] = useState(false);

  useEffect(() => {
    if (!defaultRun) {
      setSelectedItem(defaultValue);
      setDefaultRun(true);
    }
    setValue(name, selectedItem);
  });

  const generateRadioOptions = () =>
    optionsList.map((singleOption) => (
      <FormControlLabel
        key={singleOption.label}
        value={singleOption.value}
        label={
          <>
            {singleOption.label}
            {singleOption.toolTip && (
              <AdminToolTip
                message={
                  <Typography variant='caption'>
                    {singleOption.toolTip}
                  </Typography>
                }
              ></AdminToolTip>
            )}
          </>
        }
        control={<Radio />}
      />
    ));

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <RadioGroup
            value={value}
            onChange={(e) => {
              setValue(name, e.target.value);
              submitfunction();
            }}
          >
            {generateRadioOptions()}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
