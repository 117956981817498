import { Grid, Link as UiLink, TableCell } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import AdminPage from '../../components/AdminPage';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { AdminTableRow } from '../../components/AdminTable';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    paddingBottom: theme.spacing(2),
  },
}));

const itemTemplate = ({ identifier, run }, index) => (
  <AdminTableRow key={index}>
    <TableCell>{identifier}</TableCell>
    <TableCell>
      <UiLink component={Link} to={`/notifications/${identifier}/${run}`}>
        {run}
      </UiLink>
    </TableCell>
  </AdminTableRow>
);

const NotificationList = () => {
  const classes = useStyles();

  return (
    <AdminPage
      animation={true}
      title='Notifications'
      newAction='/notifications/new'
    >
      <Grid className={classes.card}>
        <AdminPaginatedList
          title='Notification Runs'
          columns={['identifier', 'runId']}
          url='/admin/notifications/rollup'
          itemTemplate={itemTemplate}
        />
      </Grid>
    </AdminPage>
  );
};

export default NotificationList;
