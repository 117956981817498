import { WidgetProps } from '@rjsf/utils';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import React, { useEffect, useState } from 'react';

export const AdminMarkdownWidget = function (props: WidgetProps) {
  const [isLoaded, setIsLoaded] = useState<any>(undefined);
  const editorRef = React.createRef<Editor>();

  const load = () => {
    if (editorRef && editorRef.current && props.value) {
      const instance = editorRef.current.getInstance();
      instance.setMarkdown(props.value);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (!isLoaded) {
      load();
    }
  }, [props]);

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const setValue = async () => {
    await delay(100);

    if (editorRef && editorRef.current) {
      const instance = editorRef.current.getInstance();
      const md = instance.getMarkdown();

      console.log(`setValue`);

      props.onChange(md);
    }
  };

  return (
    <>
      <Editor
        ref={editorRef}
        previewStyle='vertical'
        height='600px'
        useCommandShortcut={true}
        onChange={setValue}
        usageStatistics={false}
      />
    </>
  );
};
