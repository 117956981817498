import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import AdminFormCallout from './AdminFormCallout';

export function ContentCollectionForm({ data, onSubmit }): any {
  const [entity, setEntity]: any = useState<string | undefined>(undefined);

  const validate = (values) => {
    const errors: any = {};
    if (values.name.length === 0) {
      errors.name = 'Please enter a name';
    }
    return errors;
  };

  const slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

  const formik = useFormik({
    initialValues: {
      name: data.name ? data.name : '',
      description: data.description ? data.description : '',
    },
    onSubmit: async (values) => {
      await onSubmit({
        name: values.name,
        entity: entity,
        description: values.description,
      });
    },
    validate,
    validateOnChange: false,
  });

  useEffect(() => {
    if (data && data.entity) {
      setEntity(data.entity);
    }
  }, [data]);

  const handleNameToEntity = (val) => {
    setEntity(slugify(val));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid xs={8}>
          <FormControl fullWidth style={{ paddingTop: 20, paddingBottom: 20 }}>
            <TextField
              helperText={
                formik.errors.name
                  ? formik.errors.name
                  : 'Collection names should be namespaced and with an underscore (e.g. winmo_moments, fox_episodes, etc.)'
              }
              id='name'
              name='name'
              type='text'
              variant='outlined'
              label='Name'
              required
              defaultValue={data.name ? data.name : ''}
              onChange={(e) => {
                handleNameToEntity(e.target.value);
                formik.handleChange(e);
              }}
            />
          </FormControl>
          <FormControl fullWidth style={{ paddingTop: 20, paddingBottom: 20 }}>
            <TextField
              helperText={
                formik.errors.description
                  ? formik.errors.description
                  : 'A description will help make it clear how this is used, highly recommended!'
              }
              id='description'
              name='description'
              type='text'
              variant='outlined'
              label='Description'
              required
              defaultValue={data.description ? data.description : ''}
              onChange={formik.handleChange}
            />
          </FormControl>
          <FormControl>
            <Button variant='contained' size='large' id='submit' type='submit'>
              Save Changes
            </Button>
          </FormControl>
        </Grid>
        <Grid xs={4}>
          <AdminFormCallout>
            <Typography variant='h6'>
              <strong>Collection Entity</strong>
            </Typography>
            <Typography variant='body1'>
              <code style={{ color: 'black' }}>{entity}</code>
            </Typography>
            <Typography variant='body2' style={{ paddingTop: 20 }}>
              When you are interacting with this collection over the API or
              referencing content in analytics, you will use the value of{' '}
              <code style={{ color: 'black' }}>{entity}</code>, which is a
              unique slug generated from your collection name.
            </Typography>
          </AdminFormCallout>
        </Grid>
      </Grid>
    </form>
  );
}
