import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Grid, Link as UiLink, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AdminCard from '../../../components/AdminCard';
import AdminCardList from '../../../components/AdminCardList';
import AdminPage from '../../../components/AdminPage';
import { AdminToolTip } from '../../../components/AdminToolTip';

const ContentCollectionList = () => (
  <AdminPage title='Content' newAction='/content/collections/new'>
    <AdminCardList
      url='/admin/content/collections'
      emptyTemplate={() => <h1>empty</h1>}
      itemTemplate={({ id, name, entity, description }) => (
        <Grid item xs={6}>
          <AdminCard
            title={name}
            editAction={`/content/collections/${id}/edit`}
            deleteAction={`/content/collections/${id}/delete`}
          >
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Typography variant='body2'>
                  id: {id}{' '}
                  <UiLink>
                    <ContentCopyIcon
                      htmlColor='#000000'
                      fontSize='small'
                      onClick={() => {
                        navigator.clipboard.writeText(id).catch((err) => {
                          console.log(err);
                        });
                      }}
                    />
                  </UiLink>
                  <br /> Entity: {entity}
                  <AdminToolTip
                    message={
                      <Typography variant='caption'>
                        The <strong>entity</strong> field is used when you are
                        interacting with the API and through the SDK
                      </Typography>
                    }
                  ></AdminToolTip>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <em>{description}</em>
                <br />
                <br />
                <UiLink component={Link} to={`/content/collections/${id}`}>
                  <Button color='primary'>Manage Content</Button>
                </UiLink>
              </Grid>
            </Grid>
          </AdminCard>
        </Grid>
      )}
    />
  </AdminPage>
);

export default ContentCollectionList;
