import { Grid, Link as UiLink, TableCell } from '@mui/material';
import Moment from 'react-moment';
import { Link, useLocation } from 'react-router-dom';
import AdminPage from '../../components/AdminPage';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { AdminTableRow } from '../../components/AdminTable';

const AdminActivity = () => {
  const location: any = useLocation();
  return (
    <>
      <AdminPage animation={true} title='Recent Activity'>
        <Grid>
          <AdminPaginatedList
            title='Recent Activity'
            columns={[
              'admin',
              'timestamp',
              'action',
              'entity',
              'id',
              'properties',
            ]}
            url='/admin/activity/admin'
            itemTemplate={activityTemplate}
            filterKeys={['name', 'status']}
          />
        </Grid>
      </AdminPage>
    </>
  );
};

const activityTemplate = (data, index) => (
  <AdminTableRow key={index}>
    <TableCell>
      <UiLink component={Link} to={`/admins/${data.userId}`} color='primary'>
        {data.userId}
      </UiLink>
    </TableCell>
    <TableCell>
      <Moment fromNow>{data.createdAt}</Moment>
    </TableCell>
    <TableCell>{data.action}</TableCell>
    <TableCell>{data.entityType}</TableCell>
    <TableCell>{data.entityId}</TableCell>
    <TableCell>{JSON.stringify(data.properties)}</TableCell>
  </AdminTableRow>
);

export default AdminActivity;
