import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import TrackerSelectorGrid from './TrackerSelectorGrid';

const BeaconEditTrackers = ({ beacon, setSelectedDetailTab }) => {
  const [selectedBeacon, setSelectedBeacon] = useState({});
  const [selectedTrackers, setSelectedTrackers] = useState<Array<any>>(
    beacon.trackers
  );

  useEffect(() => {
    setSelectedDetailTab('beaconTracker');
    setSelectedBeacon(beacon);
  }, [beacon]);

  return (
    <Grid>
      <TrackerSelectorGrid
        showSettings={true}
        onChange={(selected) => {
          setSelectedTrackers(selected);
        }}
        beacon={selectedBeacon}
        displayType={'Table'}
      />
    </Grid>
  );
};

export default BeaconEditTrackers;
