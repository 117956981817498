import { useEffect, useState } from 'react';
import { useBlueprintAdmin } from './AdminProvider';

const AdminUserInfo = ({ adminUserId = undefined }: any) => {
  const [admin, setAdmin] = useState<undefined | any>(undefined);
  const { adminApi } = useBlueprintAdmin();

  useEffect(() => {
    if (adminUserId && adminApi) {
      adminApi
        .getAdminById(adminUserId)
        .then(({ data }) => {
          setAdmin(data);
        })
        .catch((err) => console.error(err));
    }
  }, [adminUserId]);

  return (
    <>
      {!admin && <>Loading</>}
      {admin && <>{admin.username}</>}
    </>
  );
};

export default AdminUserInfo;
