import {
  Button,
  Container,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ADMIN_ONBOARDING_STEP } from '../api/lib/constants';
import { LocalStorage } from '../api/lib/storage';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const AdminFlow = ({
  flow,
  backButton = false,
  disableLastStep = false,
  saveLocal = false,
  ...props
}: {
  flow: Array<{
    title: string;
    description: string;
    template: React.ReactNode;
    onSubmit: any;
  }>;
  backButton?: boolean;
  disableLastStep?: boolean;
  saveLocal?: boolean;
  props?: {};
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [disableFinish, setDisableFinish] = useState(false);

  useEffect(() => {
    if (saveLocal) {
      const localStorageStep = new LocalStorage().get(ADMIN_ONBOARDING_STEP);
      if (localStorageStep) {
        setActiveStep(parseInt(localStorageStep));
      }
    }
  }, []);

  useEffect(() => {
    if (saveLocal) {
      new LocalStorage().set(ADMIN_ONBOARDING_STEP, activeStep.toString());
    }
  }, [activeStep]);

  const handleNext = async () => {
    const checkValidation = await flow[activeStep].onSubmit();
    if (flow.length - 1 != activeStep && checkValidation) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (flow.length - 1 === activeStep) {
      if (disableLastStep) {
        setDisableFinish(true);
      }
    }
  };

  const handleBack = () => {
    if (!(activeStep - 1 < 0))
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setDisableFinish(false);
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={3}>
        <Stepper
          activeStep={activeStep}
          orientation='vertical'
          style={{ paddingTop: '30px' }}
        >
          {flow.map(({ title, description }) => {
            const stepProps: { completed?: boolean } = {};
            if (disableFinish) {
              return (
                <Step key={title} completed={disableFinish}>
                  <StepLabel>{title}</StepLabel>
                  <StepContent>
                    <Typography>{description}</Typography>
                  </StepContent>
                </Step>
              );
            } else {
              return (
                <Step key={title}>
                  <StepLabel>{title}</StepLabel>
                  <StepContent>
                    <Typography>{description}</Typography>
                  </StepContent>
                </Step>
              );
            }
          })}
        </Stepper>
      </Grid>
      <Grid item xs={9}>
        <Container>
          {flow[activeStep].template}
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            {' '}
            {backButton && (
              <Grid item>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => handleBack()}
                  className={classes.button}
                >
                  Back
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={async () => await handleNext()}
                className={classes.button}
                disabled={disableFinish}
              >
                {activeStep === flow.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default AdminFlow;
