import AdminPage from '../../../components/AdminPage';
import { Link } from 'react-router-dom';
import AdminPaginatedList from '../../../components/AdminPaginatedList';
import { TableRow, TableCell, Link as UiLink } from '@mui/material';

const UserInviteList = () => (
  <AdminPage
    animation={true}
    title='User Invites'
    newAction='/users/invites/new'
  >
    <AdminPaginatedList
      columns={['id', 'userId']}
      url='/admin/invites'
      itemTemplate={({ id, userId }, index) => (
        <TableRow key={index}>
          <UiLink component={Link} to={`/users/invites/${id}`}>
            <TableCell>{id}</TableCell>
          </UiLink>
          <TableCell>{userId}</TableCell>
        </TableRow>
      )}
      filterKeys={[]}
    />
  </AdminPage>
);

export default UserInviteList;
