import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid, Card, Container, Theme, withStyles } from '@mui/material';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  tabHeader: {
    width: '100%',
    paddingLeft: 12,
    paddingTop: 5,
    paddingBottom: 5,
    color: theme.palette.grey[600],
    textTransform: 'uppercase',
    fontWeight: 'bolder',
  },
  tab: {
    root: {
      fontSize: '0.75rem',
      textAlign: 'left',
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    wrapper: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
      },
    },
  },
}));

const AdminVerticalTabs = Tabs;

// TODO: STYLEFIX
// const AdminVerticalTabs = withStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.grey[100],
//   },
//   indicator: {
//     display: 'none',
//   },
// }))(Tabs);

// TODO: STYLEFIX
// const AdminTab = withStyles((theme) => ({
// root: {
//   fontSize: '0.75rem',
//   textAlign: 'left',
//   backgroundColor: theme.palette.common.white,
//   '&:hover': {
//     backgroundColor: theme.palette.primary.light,
//   },
// },
// wrapper: {
//   alignItems: 'flex-start',
//   paddingTop: theme.spacing(2),
//   paddingBottom: theme.spacing(2),
// },
// selected: {
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.common.white,
//   '&:hover': {
//     backgroundColor: theme.palette.primary.dark,
//     color: theme.palette.common.white,
//   },
// },
// }))(Tab);

const CustomTabs = ({
  title,
  tabs,
  className = '',
  tabOrientation,
  removeTitle = false,
  tabLocation = 'sidebar',
  ...props
}: {
  title?: string;
  tabs: Array<{
    title: string;
    template: React.ReactNode;
    icon?: React.ReactNode;
  }>;
  className?: string;
  props?: {};
  tabOrientation?: any;
  removeTitle?: any;
  tabLocation?: any;
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(1);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const titleTab = () => (
    <Card>
      <AdminVerticalTabs
        orientation={tabOrientation ? tabOrientation : 'vertical'}
        value={value}
        onChange={handleChange}
        {...props}
      >
        {!removeTitle && (
          <Typography variant='caption' className={classes.tabHeader}>
            {title ? title : 'Manage'}
          </Typography>
        )}
        {tabs.map(({ title, template, icon }, i) => (
          <Tab label={title} key={i} />
        ))}
      </AdminVerticalTabs>
    </Card>
  );

  const normalTabs = () => (
    <>
      {tabs.map(({ title, template, icon }, i) => (
        <>
          {(!removeTitle ? value - 1 : value) === i && (
            <Grid container item style={{ padding: 0 }} key={i} role='tabpanel'>
              <>{template}</>
            </Grid>
          )}
        </>
      ))}
    </>
  );

  return (
    <>
      <Grid container>
        {tabLocation === 'top' && (
          <>
            <Grid
              item
              xs={12}
              justifyContent='center'
              container
              direction='row'
            >
              {titleTab()}
            </Grid>
            <Grid container item xs={12}>
              {normalTabs()}
            </Grid>
          </>
        )}
        {tabLocation === 'sidebar' && (
          <>
            <Grid container item xs={2}>
              {titleTab()}
            </Grid>
            <Grid container item xs={10}>
              {normalTabs()}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default CustomTabs;
