import { PublicClientApplication } from '@azure/msal-browser';
import { StyledEngineProvider } from '@mui/material/styles';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { msalConfig } from './pages/auth/sso/auth';
import getEnv from './utils/getEnv';

// Required only for OKTA Auth
import { OktaAuth } from '@okta/okta-auth-js';

const azureLoginInstance =
  getEnv('REACT_APP_AUTH_METHOD') === 'provider' ||
  getEnv('REACT_APP_AUTH_METHOD') === 'azure'
    ? new PublicClientApplication(msalConfig)
    : null;

const oktaLoginInstance =
  getEnv('REACT_APP_AUTH_METHOD') === 'okta'
    ? new OktaAuth({
        issuer: getEnv('REACT_APP_AUTH_ISSUER'),
        clientId: getEnv('REACT_APP_AUTH_CLIENTID'),
        redirectUri: window.location.origin + '/auth/callback',
      })
    : null;

ReactDOM.render(
  <Router>
    <StyledEngineProvider injectFirst>
      <App
        azureLoginInstance={azureLoginInstance}
        oktaLoginInstance={oktaLoginInstance}
      />
    </StyledEngineProvider>
  </Router>,
  document.getElementById('root')
);
