import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';

interface FormInputProps {
  name: string;
  control: any;
  label: string;
  widthvar?: string;
  defaultValue?: string | null;
  setValue: any;
  submitfunction?: any;
  errorCheck?: any;
  testId?: string;
}

export const FormInputText = ({
  name,
  control,
  label,
  widthvar,
  defaultValue,
  setValue,
  submitfunction,
  errorCheck,
  testId,
}: FormInputProps) => {
  const useStyles = makeStyles({
    formObj: {
      width: widthvar || '50%',
      marginTop: 10,
    },
  });
  const [defaultRun, setDefaultRun] = useState(false);
  const [errorState, setErrorState] = useState(false);
  useEffect(() => {
    if (!defaultRun) {
      setDefaultRun(true);
    }
  });

  const ErrorCheckFunction = (value) => {
    console.log(value);
    if (errorCheck === 'integerCheck') {
      if (!(value === '') && !/^[0-9]+$/.test(value)) {
        setErrorState(true);
      } else {
        setErrorState(false);
      }
    }
    setValue(name, value);
  };

  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          className={classes.formObj}
          helperText={errorState ? 'Input must be a number' : ''}
          size='small'
          error={errorState}
          onChange={(e) => {
            setValue(name, e.target.value);
            if (errorCheck) {
              ErrorCheckFunction(e.target.value);
            }
            submitfunction();
          }}
          value={value}
          fullWidth
          defaultValue={!errorState ? defaultValue : value}
          label={label}
          variant='outlined'
          inputProps={{
            'data-testid': testId,
          }}
        />
      )}
    />
  );
};
