import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Box } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';

interface FormInputProps {
  name: string;
  control: any;
  label: string;
  optionsList?: any;
  defaultValue?: any;
  setValue: any;
  submitfunction?: any;
}

const useStyles = makeStyles({
  formObj: {
    width: '30%',
    marginTop: 12,
  },
});

export const FormInputDropdown: React.FC<FormInputProps> = ({
  name,
  control,
  label,
  optionsList,
  setValue,
  defaultValue,
  submitfunction,
}) => {
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [defaultRun, setDefaultRun] = useState(false);

  useEffect(() => {
    if (!defaultRun) {
      setSelectedItem(defaultValue);
      setDefaultRun(true);
    }
    setValue(name, selectedItem);
  });

  const options = optionsList;
  const classes = useStyles();
  const generateSingleOptions = () =>
    options.map((option: any) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));

  return (
    <Box className={classes.formObj}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Controller
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(e) => {
                setValue(name, e.target.value);
                submitfunction();
              }}
              value={value}
            >
              {generateSingleOptions()}
            </Select>
          )}
          control={control}
          name={name}
        />
      </FormControl>
    </Box>
  );
};
