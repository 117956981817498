import { Button, Grid, Step, StepLabel, Stepper, Theme } from '@mui/material';
import { useState } from 'react';
import AdminCard from './AdminCard';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

interface Flow {
  title: string;
  template: React.ReactNode;
  onSubmit: any;
  onBackup: any;
  onCancel: any;
  hidden: boolean;
  disableBackButton?: boolean;
  buttonLabel?: string;
  disableCancelButton?: boolean;
}

const AdminHorizontalFlow = ({
  flow,
  submitButtonLabel = 'FINAL',
  ...props
}: {
  flow: Array<Flow>;
  submitButtonLabel?: string;
  props?: {};
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = async () => {
    const errorCheck = await flow[activeStep].onSubmit();
    if (errorCheck) {
      if (!(flow.length - 1 === activeStep)) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (flow[activeStep + 1].hidden) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    }
  };
  const handleCancel = async () => {
    await flow[activeStep].onCancel();
  };

  const handleBack = async () => {
    await flow[activeStep].onBackup();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const getButtonLabel = () => {
    if (activeStep === flow.length - 1) {
      return submitButtonLabel;
    } else if (flow[activeStep].buttonLabel) {
      return flow[activeStep].buttonLabel;
    } else {
      return 'NEXT';
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Stepper activeStep={activeStep} className={classes.root}>
          {flow
            .filter((value) => !value.hidden)
            .map(({ title }) => (
              <Step key={title}>
                <StepLabel>{title}</StepLabel>
              </Step>
            ))}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        <AdminCard>
          {flow[activeStep].template}
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Button
                variant='text'
                color='primary'
                onClick={() => handleCancel()}
                className={classes.button}
                disabled={flow[activeStep]?.disableCancelButton}
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='outlined'
                color='primary'
                onClick={async () => await handleBack()}
                className={classes.button}
                disabled={
                  activeStep <= 0 || flow[activeStep]?.disableBackButton
                }
              >
                BACK
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={async () => await handleNext()}
                className={classes.button}
              >
                {getButtonLabel()}
              </Button>
            </Grid>
          </Grid>
        </AdminCard>
      </Grid>
    </Grid>
  );
};

export default AdminHorizontalFlow;
