import { Link } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';
import { useBlueprintAdmin } from './AdminProvider';

interface IAssetLink {
  id: string;
  caption: string;
}

function AdminAssetLink({ id, caption }: IAssetLink) {
  const { jwtToken, adminAssetApi } = useBlueprintAdmin();
  const [assetLink, setAssetLink] = useState<string | undefined>('');

  useEffect(() => {
    if (!id || !jwtToken || !adminAssetApi) {
      return;
    } else {
      adminAssetApi
        .getAssetSignedUrl(id)
        .then(({ data }) => {
          setAssetLink(data);
        })
        .catch((e) => console.error(e));
    }
  }, [id, jwtToken]);

  return (
    <>
      {id && !assetLink && (
        <Skeleton variant='rectangular' height={17} width={175} />
      )}
      {id && assetLink && (
        <>
          <Link href={assetLink} target='_blank' rel='noreferrer'>
            {caption}
          </Link>
        </>
      )}
    </>
  );
}

export default AdminAssetLink;
