import { Grid } from '@mui/material';
import { useState } from 'react';
import AdminCard from './AdminCard';

const AdminToggleGrid = ({ onChange }: any) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);

  const options = [
    {
      id: 'core',
      title: 'Core',
      description:
        'Includes the critical features for Blueprint which are Administrators and Users.',
      enabled: true,
    },
    {
      id: 'activity',
      title: 'Activity',
      description:
        'Allows for activity to be collected from web & mobile endpoints.',
      enabled: false,
    },
    {
      id: 'identity',
      title: 'Identity',
      description:
        'Provides identity mapping (e.g. match attributes such as IP, cookies, or profile information to form a singular identity)',
      enabled: true,
    },
    {
      id: 'content',
      title: 'Content',
      description:
        'Enables admin created content, content via integrations (such as an existing CMS), or user-generated content',
      enabled: true,
    },
    {
      id: 'notifications',
      title: 'Notifications',
      description:
        'Enables SMS, Email, or Push based notifications either based on actions or as a part of a campaign',
      enabled: true,
    },
  ];

  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  const onSelect = (id, selected) => {
    let newOptions = selectedOptions;

    if (selected) {
      newOptions.push(id);
    } else {
      newOptions = arrayRemove(newOptions, id);
    }

    setSelectedOptions(newOptions);
    onChange(newOptions);
  };

  return (
    <>
      <Grid container spacing={2}>
        {options.map(({ id, title, description, enabled }) => (
          <Grid item xs={4} key={id}>
            <AdminCard
              id={id}
              onSelect={(id, selected) => onSelect(id, selected)}
              selectable={true}
              title={title}
            >
              <p>
                {description} {enabled}
              </p>
            </AdminCard>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AdminToggleGrid;
