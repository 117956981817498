import { Grid } from '@mui/material';
import DestinationSelectorGrid from './DestinationSelectorGrid';
import { useEffect } from 'react';

const BeaconEditDestinations = ({ beacon, setSelectedDetailTab }) => {
  useEffect(() => {
    setSelectedDetailTab('Destinations');
  });

  return (
    <Grid>
      {beacon.destinations.length === 0 && (
        <p>No destination set, please press the + icon to add</p>
      )}
      <DestinationSelectorGrid beacon={beacon} />
    </Grid>
  );
};

export default BeaconEditDestinations;
