export const trimText = (val, limit = 15) => {
  if (val) {
    // eslint-disable-next-line
    return val.slice(0, limit) + (val.length > limit ? '...' : '');
  } else {
    return '-';
  }
};

export const getUserApiRoot = (adminApiRoot: string) => {
  return adminApiRoot.replace('admin.', '');
};
