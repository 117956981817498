import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import { Controller } from 'react-hook-form';

interface FormInputProps {
  name: string;
  control: any;
  label: string;
  setValue: any;
  optionsList?: any;
  defaultValues?: any;
  submitfunction?: any;
  disabledOptions?: Array<string>;
}

export const FormInputMultiCheckbox: React.FC<FormInputProps> = ({
  name,
  control,
  setValue,
  optionsList,
  disabledOptions,
  defaultValues,
  submitfunction,
}) => {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [defaultRun, setDefaultRun] = useState(false);

  const handleSelect = (value: any) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item: any) => item !== value);
      setSelectedItems(remaining);
      setValue(name, remaining);
    } else {
      setSelectedItems((prevItems: any) => [...prevItems, value]);
      const newlist = selectedItems;
      newlist.push(value);
      setValue(name, newlist);
    }
    submitfunction();
  };

  useEffect(() => {
    if (!defaultRun) {
      setSelectedItems(defaultValues);
      setDefaultRun(true);
    }
    setValue(name, selectedItems);
  });

  return (
    <FormControl fullWidth variant={'outlined'}>
      <div>
        {optionsList.map((option: any) => (
          <Grid item sm={12} key={`FormCheckBox-${option.label}`}>
            <FormControlLabel
              control={
                <Controller
                  name={name}
                  render={({}) => (
                    <Checkbox
                      checked={selectedItems.includes(option.value)}
                      onChange={() => handleSelect(option.value)}
                      disabled={disabledOptions?.includes(option.value)}
                    />
                  )}
                  control={control}
                />
              }
              label={option.label}
              key={option.value}
            />
          </Grid>
        ))}
      </div>
    </FormControl>
  );
};
