import { Box, Button, FormControl, TextField } from '@mui/material';
import { useFormik } from 'formik';
interface MFAProps {
  username: string;
  handleSubmit: (username: string, token: string) => void;
}

const AdminMFA = ({ username, handleSubmit }: MFAProps) => {
  const formik = useFormik({
    initialValues: {
      token: '',
    },
    // eslint-disable-next-line
    onSubmit: async (values) => {
      handleSubmit(username, values.token);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box maxWidth={{ xs: 400 }} my={2}>
        <FormControl margin='dense' fullWidth>
          <TextField
            id='token'
            name='token'
            type='text'
            variant='outlined'
            label='Token'
            value={formik.values.token}
            onChange={formik.handleChange}
          />
        </FormControl>
      </Box>
      <Button
        variant='contained'
        color='primary'
        size='large'
        id='submit'
        type='submit'
      >
        Submit
      </Button>
    </form>
  );
};

export default AdminMFA;
