import AdminPage from '../../components/AdminPage';
import { Link } from 'react-router-dom';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { Link as UiLink, TableCell } from '@mui/material';
import { AdminTableRow } from '../../components/AdminTable';

const SegmentList = () => {
  const itemTemplate = ({ id, name, description }, index) => (
    <AdminTableRow key={index}>
      <TableCell>
        <UiLink component={Link} to={`/segments/${id}`}>
          {id}
        </UiLink>
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{description}</TableCell>
    </AdminTableRow>
  );

  return (
    <AdminPage animation={true} title='Segments' newAction='/segments/new'>
      <AdminPaginatedList
        columns={['id', 'name', 'description']}
        url='/admin/segments'
        itemTemplate={itemTemplate}
      />
    </AdminPage>
  );
};

export default SegmentList;
