import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { AdminToolTip } from '../../../components/AdminToolTip';

const DestinationDetails = ({ formik }) => {
  const [platformId, setPlatformId] = useState(formik.values.platformId);

  useEffect(() => {
    setPlatformId(formik.values.platformId);
  }, []);

  const platforms = [
    {
      label: 'Facebook',
      value: 'FB',
    },
  ];
  return (
    <>
      <Typography variant='h4' style={{ flex: 1 }}>
        Destination Details
      </Typography>

      <Grid container direction='column'>
        {/* Platform ID */}
        <Grid item xs={3}>
          <Box marginTop={2}>
            <Typography variant='subtitle1'>
              Select a platform id
              <AdminToolTip
                message={
                  <Typography variant='caption'>
                    Select which platform to send data to (e.g. facebook,
                    twitter)
                  </Typography>
                }
              ></AdminToolTip>
            </Typography>
            <TextField
              select
              label='Platform Id'
              id='platformId'
              name='platformId'
              variant='outlined'
              onChange={formik.handleChange}
              defaultValue={platformId}
              error={formik.errors.platformId}
              helperText={formik.errors.platformId}
              fullWidth
              required
              inputProps={{ 'data-testid': 'platformId-input' }}
            >
              {platforms?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Grid>

        {formik.values.platformId === 'FB' && (
          <>
            <Grid item xs={3}>
              <Box marginTop={2}>
                <Typography variant='subtitle1'>
                  Type a destination name
                  <AdminToolTip
                    message={
                      <Typography variant='caption'>
                        This is just used to help identify your destinations.
                      </Typography>
                    }
                  ></AdminToolTip>
                </Typography>
                <TextField
                  label='Destination Name'
                  id='destinationName'
                  name='destinationName'
                  variant='outlined'
                  onChange={formik.handleChange}
                  defaultValue={formik.values.destinationName}
                  error={formik.errors.destinationName}
                  helperText={formik.errors.destinationName}
                  fullWidth
                  required
                  inputProps={{ 'data-testid': 'destinationName-input' }}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box marginTop={2}>
                <Typography variant='subtitle1'>
                  Type a destination id
                  <AdminToolTip
                    message={
                      <Typography variant='caption'>
                        Destination is the pixel id that is tied to this beacon
                      </Typography>
                    }
                  ></AdminToolTip>
                </Typography>
                <TextField
                  label='Destination Id'
                  id='destinationId'
                  name='destinationId'
                  variant='outlined'
                  onChange={formik.handleChange}
                  defaultValue={formik.values.destinationId}
                  error={formik.errors.destinationId}
                  helperText={formik.errors.destinationId}
                  fullWidth
                  required
                  inputProps={{ 'data-testid': 'destinationId-input' }}
                />
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box marginY={2}>
                <Typography variant='subtitle1'>
                  Type associated access token
                  <AdminToolTip
                    message={
                      <Typography variant='caption'>
                        Access token is the Facebook CAPI access token that is
                        associated with the pixel id you set up as a destination
                      </Typography>
                    }
                  ></AdminToolTip>
                </Typography>
                <TextField
                  label='Access Token'
                  id='accessToken'
                  name='accessToken'
                  variant='outlined'
                  onChange={formik.handleChange}
                  defaultValue={formik.values.accessToken}
                  error={formik.errors.accessToken}
                  helperText={formik.errors.accessToken}
                  fullWidth
                  required
                  inputProps={{ 'data-testid': 'accessToken-input' }}
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default DestinationDetails;
