import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { useEffect, useState } from 'react';
import { useBlueprintAdmin } from '../AdminProvider';

interface ActionModalVars {
  id: any;
  onSaved: any;
  selectedAction: any;
  setSelectedAction?: any;
  passVariables?: any;
  errorHandler?: boolean;
  currentActions?: any;
  popUpModal?: boolean;
}

const ActionsModal = ({
  id,
  onSaved,
  selectedAction,
  setSelectedAction,
  passVariables,
  errorHandler = false,
  currentActions = {},
  popUpModal = false,
}: ActionModalVars) => {
  const [identifier, setIdentifier] = useState<string>();
  const [actionMeta, setActionMeta] = useState<any>();
  const [actions, setActions] = useState<Array<any>>([]);
  const { adminTriggerApi } = useBlueprintAdmin();

  useEffect(() => {
    adminTriggerApi!
      .getActions()
      .then((data) => {
        if (data.success) {
          setActions(data.data);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    if (selectedAction && actions) {
      for (const _action of actions) {
        if (selectedAction.id === _action.script) {
          setIdentifier(selectedAction.id);
          setActionMeta(_action);
        }
      }
    }
  }, [actionMeta, selectedAction, actions]);

  const handleIdentifierChange = (event: SelectChangeEvent<unknown>) => {
    const val = event.target.value as string;
    for (const _action of actions) {
      if (_action.script === val) {
        setIdentifier(val);
        setActionMeta(_action);
        if (setSelectedAction) {
          setSelectedAction(val);
        }
      }
    }
  };

  // const onChangePassVariables = ({ formData }, e) => {
  //   if (passVariables) {
  //     passVariables({
  //       id: actionMeta.script,
  //       method: 'base',
  //       properties: formData,
  //     });
  //   }
  // };

  const handleSubmit = async (e: any) => {
    if (identifier) {
      if (
        currentActions &&
        currentActions.map((value) => value.id).includes(identifier)
      ) {
        const identifierObject = currentActions.findIndex(
          (obj) => obj.id === identifier
        );
        currentActions[identifierObject] = {
          id: identifier,
          method: 'base',
          properties: e.formData,
        };
        adminTriggerApi!
          .updateTrigger(id, {
            actions: currentActions,
          })
          .then((data) => {
            if (data.success) {
              onSaved();
            }
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        const response = await adminTriggerApi!.createTriggerAction(id, {
          id: identifier,
          method: 'base',
          properties: e.formData,
        });
        if (response.success) onSaved();
        else {
          console.error('missing identifier');
        }
      }
    }
  };

  const customUISchemaFields = {
    valueTrait: {
      'ui:options': {
        orderable: false,
        style: {
          width: '10px',
        },
      },
      // classNames: classes.testing,
    },
  };
  return (
    <>
      <Grid container style={{ paddingTop: 20 }}>
        <Grid item xs={popUpModal ? 12 : 4}>
          <FormControl variant='outlined' fullWidth>
            <InputLabel id='action-select-label'>Method</InputLabel>
            <Select
              id='action-select'
              labelId='action-select-label'
              label='Method'
              value={identifier}
              onChange={handleIdentifierChange}
              error={errorHandler}
            >
              {actions?.map((item, i) => (
                <MenuItem key={i} value={item.script}>
                  {item.meta.title}
                </MenuItem>
              ))}
            </Select>
            {errorHandler && (
              <FormHelperText style={{ color: 'red' }}>
                Select A Method
              </FormHelperText>
            )}
          </FormControl>
          {identifier && actionMeta && actionMeta.meta && (
            <>
              {passVariables && (
                <Form
                  formData={selectedAction ? selectedAction.properties : {}}
                  schema={{
                    properties: actionMeta.meta.properties,
                    type: 'object',
                  }}
                  // onChange={onChangePassVariables} // LOOK HERE IF ACTIONS BREAKS
                  onSubmit={handleSubmit}
                  uiSchema={customUISchemaFields}
                  validator={validator}
                >
                  <></>
                </Form>
              )}
              {!passVariables && (
                <Form
                  formData={selectedAction ? selectedAction.properties : {}}
                  schema={{
                    properties: actionMeta.meta.properties,
                    type: 'object',
                  }}
                  onSubmit={handleSubmit}
                  validator={validator}
                >
                  <Button
                    variant='contained'
                    type='submit'
                    className='btn btn-primary'
                    style={{
                      backgroundColor: '#0070eb',
                      color: 'white',
                      float: 'right',
                    }}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ActionsModal;
