import { Chip, Link as UiLink, TableCell } from '@mui/material';
import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Link } from 'react-router-dom';
import AdminPage from '../../components/AdminPage';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import AdminPaginatedListV2 from '../../components/AdminPaginatedListV2';
import { AdminTableRow } from '../../components/AdminTable';
import getEnv from '../../utils/getEnv';
import searchClient from '../../utils/search';

const fetchData =
  (searchTerm: string) =>
  ({ pageParam = 1 }) => {
    return searchClient
      .index('users')
      .search(searchTerm, { hitsPerPage: 20, page: pageParam })
      .then((res) => {
        if (res.hits) {
          return res.hits;
        }
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

const UserList = () => {
  const [content, setContent] = useState<Array<any>>([]);
  const [search, setSearch] = useState<string>('');

  const { data, isLoading, fetchNextPage, refetch } = useInfiniteQuery(
    'users',
    fetchData(search),
    {
      getNextPageParam: (_, pages) => {
        return pages.length + 1;
      },
    }
  );

  useEffect(() => {
    /**
     * when user submits search query refetch pagination with the latest filter
     */
    refetch()
      .then((response) => {
        if (response && response.data) {
          setContent(response.data.pages.flat());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [search]);

  useEffect(() => {
    if (data && data.pages.length > 0) {
      setContent(data.pages.flat());
    }
  }, [data?.pages.length]);

  const getName = (username, traits) => {
    if (traits) {
      const names = traits.filter(function (value) {
        return value.key === 'name';
      });

      if (names) {
        if (names[0]) {
          return names[0].encryptedValue;
        } else {
          return username;
        }
      } else {
        return username;
      }
    } else {
      return username;
    }
  };

  const getChip = (status) => (
    <>
      {status === 'active' && <Chip color='primary' label={status} />}
      {status !== 'active' && <Chip color='default' label={status} />}
    </>
  );

  const userTemplate = (
    { status, id, external_id, username, identity, traits },
    index
  ) => (
    <AdminTableRow key={index}>
      <TableCell>
        <UiLink component={Link} key={id} to={`/users/${id}`}>
          {traits ? getName(username, traits) : username}
        </UiLink>
      </TableCell>
      <TableCell>{external_id}</TableCell>
      <TableCell>{getChip(status)}</TableCell>
      <TableCell>
        {identity && (
          <>
            <Chip
              component={Link}
              to={`/users/identity/${identity.id}`}
              color='primary'
              label={`${identity.idKey} - ${identity.idValue}`}
            />
          </>
        )}
      </TableCell>
      <TableCell>
        <UiLink component={Link} key={id} to={`/users/${id}`}>
          View
        </UiLink>
      </TableCell>
    </AdminTableRow>
  );

  return (
    <AdminPage title='Users'>
      {/* use meilisearch if api given */}
      {getEnv('REACT_APP_SEARCH_API_DOMAIN') && (
        <AdminPaginatedListV2
          headings={['name', 'email', 'status', 'identity', 'actions']}
          data={content}
          onInfiniteLoad={() => fetchNextPage()}
          isLoading={isLoading}
          itemTemplate={userTemplate}
          loader={
            <tr className='loader' key={0}>
              <td>Loading ...</td>
            </tr>
          }
          search
          setSearchTerm={setSearch}
        />
      )}

      {!getEnv('REACT_APP_SEARCH_API_DOMAIN') && (
        <AdminPaginatedList
          url='/admin/users'
          columns={['name', 'email', 'status', 'identity', 'actions']}
          // search={
          //   'search/user?searchFields=name,firstName,lastName&returnFields=name,firstName,lastName'
          // }
          itemTemplate={userTemplate}
        />
      )}
    </AdminPage>
  );
};

export default UserList;
