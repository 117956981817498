import { FormBuilder } from '@ginkgo-bioworks/react-json-schema-form-builder';
import MonacoEditor from '@monaco-editor/react';
import { Box, FormControl, Tab, Tabs, Typography } from '@mui/material';
import MuiForm from '@rjsf/mui';
import { RegistryFieldsType } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import Logger from '../utils/logger';
import AdminCard from './AdminCard';
import { ContentCollectionForm } from './AdminContentCollectionForm';
import { useBlueprintAdmin } from './AdminProvider';
import { AdminAssetWidget } from './forms/AdminAssetWidget';
import { AdminMarkdownWidget } from './forms/AdminMarkdownWidget';
import AutoCompleteWidget from './forms/AutoCompleteWidget';

const widgets = {
  markdown: AdminMarkdownWidget,
  autoComplete: AutoCompleteWidget,
};

const fields: RegistryFieldsType = {
  asset: AdminAssetWidget,
};

interface IProps {
  collectionId?: string;
  onSuccess: any;
  onFailure: any;
}

const AdminContentCollectionEditor = ({
  collectionId,
  onSuccess,
  onFailure,
}: IProps) => {
  const [tab, setTab] = useState(0);
  const [isValidSchema, setIsValidSchema] = useState<boolean>(true);
  const [formSchema, setFormSchema] = useState<any>(undefined);
  const [formUiSchema, setFormUiSchema] = useState<any>(undefined);
  const { config, jwtToken, adminContentApi } = useBlueprintAdmin();
  const [data, setData] = useState<any>(undefined);
  const logger = new Logger({
    name: 'AdminContentCollectionEditor',
    isEnabled: true,
  });

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    if (collectionId) {
      adminContentApi!
        .getCollection(collectionId)
        .then(({ data }) => {
          setData(data);
          setFormSchema(data.schema);
          setFormUiSchema(data.uiSchema);
          setIsValidSchema(true);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [collectionId]);

  const handleSubmit = async (values: any) => {
    if (!formSchema.properties.status) {
      console.error('need to add status field');

      formSchema.properties.status = {
        type: 'string',
        title: 'Content Status',
        description:
          'Draft content will only be viewable by administrators, Published content will follow the publish process.',
        enum: ['draft', 'published'],
      };
    }

    // If someone used the visual editor to adjust the ordering of elements, then we need to push status to the end of it
    if (
      formUiSchema &&
      formUiSchema['ui:order'] &&
      formUiSchema['ui:order'].indexOf('status') === -1
    ) {
      formUiSchema['ui:order'] = [...formUiSchema['ui:order'], 'status'];
    }

    let response;

    // If an ID is not present then POST, otherwise PATCH
    if (collectionId) {
      response = await adminContentApi?.updateCollection(collectionId, {
        name: values.name,
        entity: values.entity,
        description: values.description,
        schema: formSchema,
        uiSchema: formUiSchema,
      });
    } else {
      response = await adminContentApi?.createCollection({
        name: values.name,
        entity: values.entity,
        description: values.description,
        schema: formSchema,
        uiSchema: formUiSchema,
      });
    }

    if (response.success) {
      onSuccess(response);
    } else {
      onFailure(response);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const parseValueForEditor = (value: any) => {
    if (value) {
      try {
        if (typeof value === 'object') {
          return JSON.stringify(value, null, 2);
        } else {
          return JSON.parse(value);
        }
      } catch (error) {
        return value;
      }
    } else {
      return '{}';
    }
  };

  const formatUiSchema = (uiSchema) => {
    const parsedSchema = JSON.parse(uiSchema);

    try {
      for (const rootItem of Object.keys(parsedSchema)) {
        logger.debug(`- ${rootItem}`);

        const valueOfRootItem = parsedSchema[rootItem];

        // If the value of the root object (e.g. the name of the field), is an object
        // then it likely has a custom widget
        if (typeof valueOfRootItem === 'object') {
          // First, we'll check if its the ui:order field, let's make sure that it includes all of the fields in the schema already
          if (rootItem === 'ui:order') {
            logger.error(`ORDER: ${valueOfRootItem}`);

            // This splits the ui:order list, so we can make sure things are up to date
            const thingsInUiOrderNow = valueOfRootItem;

            logger.debug(`thingsInUiOrderNow: ${thingsInUiOrderNow}`);

            const fieldsInProperties = Object.keys(formSchema.properties);

            logger.debug(`fieldsInProperties: ${fieldsInProperties}`);

            // Things that are missing in ui:order
            const thingsMissingInUiOrder = thingsInUiOrderNow.filter(
              (item) => fieldsInProperties.indexOf(item) < 0
            );
            const thingsMissingInFieldProperties = fieldsInProperties.filter(
              (item) => thingsInUiOrderNow.indexOf(item) < 0
            );

            // There are items missing in the ui:order, gotta add em!
            if (thingsMissingInUiOrder.length > -1) {
              parsedSchema['ui:order'] = Array.from(
                new Set([
                  ...parsedSchema['ui:order'],
                  ...thingsMissingInUiOrder,
                ])
              );
            }

            logger.debug(`thingsMissingInUiOrder: ${thingsMissingInUiOrder}`);
            logger.debug(
              `thingsMissingInFieldProperties: ${thingsMissingInFieldProperties}`
            );
          } else {
            if (
              valueOfRootItem['ui:widget'] &&
              valueOfRootItem['ui:widget'] === 'markdown'
            ) {
              logger.debug(`this is a ui:widget element!`);
              parsedSchema[rootItem] = {
                md: {
                  'ui:widget': 'markdown',
                },
                html: {
                  'ui:widget': 'hidden',
                },
              };
            }
          }
        }
      }
    } catch (error) {
      logger.error(error);
    }

    return parsedSchema;
  };

  const customFormInputs = {
    asset: {
      displayName: 'Asset Uploader',
      matchIf: [
        {
          types: ['object'],
          widget: 'asset',
        },
      ],
      defaultDataSchema: {
        properties: {
          id: {
            type: 'string',
          },
          key: {
            type: 'string',
          },
        },
      },
      defaultUiSchema: {
        'ui:field': 'asset',
      },
      type: 'object',
    },
    markdown: {
      displayName: 'Markdown Field',
      matchIf: [
        {
          types: ['object'],
          widget: 'markdown',
        },
      ],
      defaultDataSchema: {
        properties: {
          md: {
            title: 'MD',
            type: 'string',
          },
          html: {
            title: 'HTML',
            type: 'string',
          },
        },
      },
      defaultUiSchema: {
        'ui:widget': 'markdown',
      },
      type: 'object',
    },
  };

  return (
    <>
      <AdminCard>
        {data && (
          <ContentCollectionForm
            data={data}
            onSubmit={async (values) => {
              await handleSubmit(values);
            }}
          />
        )}
        {!data && (
          <ContentCollectionForm
            data={{}}
            onSubmit={async (values) => {
              await handleSubmit(values);
            }}
          />
        )}
        <FormControl fullWidth style={{ paddingTop: 20, paddingBottom: 20 }}>
          <>
            <Box>
              <Tabs value={tab} onChange={handleChange}>
                <Tab label='Editor' id='tab-0' />
                <Tab label='Source' id='tab-1' disabled={!isValidSchema} />
                <Tab label='Preview' id='tab-2' disabled={!isValidSchema} />
              </Tabs>
            </Box>
            <Box>
              <div
                role='tabpanel'
                hidden={tab !== 0}
                id={`vertical-tabpanel-${0}`}
                aria-labelledby={`vertical-tab-${0}`}
              >
                {tab === 0 && (
                  <AdminCard>
                    <FormBuilder
                      schema={JSON.stringify(formSchema)}
                      uischema={JSON.stringify(formUiSchema)}
                      onChange={(newSchema: any, newUiSchema: any) => {
                        console.error(newUiSchema);
                        try {
                          setFormSchema(JSON.parse(newSchema));
                          setFormUiSchema(formatUiSchema(newUiSchema));
                        } catch (error) {}
                      }}
                      mods={{
                        customFormInputs,
                      }}
                    />
                  </AdminCard>
                )}
              </div>
              <div
                role='tabpanel'
                hidden={tab !== 1}
                id={`vertical-tabpanel-${1}`}
                aria-labelledby={`vertical-tab-${1}`}
              >
                {tab === 1 && (
                  <AdminCard>
                    <Typography variant='h6'>Schema</Typography>
                    <Typography variant='caption'>
                      This schema viewer shows your JSON Schema
                    </Typography>
                    <MonacoEditor
                      height='600px'
                      theme='vs-dark'
                      defaultLanguage='json'
                      value={parseValueForEditor(formSchema)}
                      onChange={(value, event) => {
                        if (value) {
                          try {
                            setFormSchema(JSON.parse(value));
                            setIsValidSchema(true);
                          } catch (error) {
                            setFormSchema(value);
                            setIsValidSchema(false);
                          }
                        }
                      }}
                      options={{
                        minimap: {
                          enabled: false,
                        },
                        automaticLayout: true,
                      }}
                    />

                    <Typography variant='h6'>UI Schema</Typography>
                    <Typography variant='caption'>
                      This schema viewer shows your JSON UI Schema
                    </Typography>
                    <MonacoEditor
                      height='600px'
                      theme='vs-dark'
                      defaultLanguage='json'
                      value={parseValueForEditor(formUiSchema)}
                      onChange={(value, event) => {
                        if (value) {
                          try {
                            setFormUiSchema(JSON.parse(value));
                            setIsValidSchema(true);
                          } catch (error) {
                            setFormUiSchema(value);
                            setIsValidSchema(false);
                          }
                        }
                      }}
                      options={{
                        minimap: {
                          enabled: false,
                        },
                        automaticLayout: true,
                      }}
                    />
                  </AdminCard>
                )}
              </div>
              <div
                role='tabpanel'
                hidden={tab !== 2}
                id={`vertical-tabpanel-${2}`}
                aria-labelledby={`vertical-tab-${2}`}
              >
                {tab === 2 && (
                  <>
                    {formSchema &&
                      formSchema.properties &&
                      Object.keys(formSchema.properties) &&
                      Object.keys(formSchema.properties).length > 0 && (
                        <AdminCard>
                          <MuiForm
                            schema={formSchema}
                            uiSchema={formUiSchema}
                            validator={validator}
                            widgets={widgets}
                            fields={fields}
                          />
                        </AdminCard>
                      )}
                  </>
                )}
              </div>
            </Box>
          </>
        </FormControl>
      </AdminCard>
    </>
  );
};

export { AdminContentCollectionEditor };
