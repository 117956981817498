import { Marked } from '@ts-stack/markdown';

export const runPreSaveConditions = (formData) => {
  // PRE SAVE ACTIONS
  for (const field of Object.keys(formData)) {
    const fieldVal = formData[field];
    // If the field is an object, then its a nested set of components, need to iterate through them
    if (fieldVal && typeof formData[field] === 'object') {
      // SUPPORT MARKDOWN EDITOR
      // This specific use case requires that we are only doing it on the object level
      // If the object level contains an MD record, we'll then save the HTML output of it
      if (Object.keys(fieldVal).indexOf('md') !== -1) {
        console.log('had md field!');
        // If the object contains an md field, then we know there is an HTML one
        fieldVal['html'] = Marked.parse(fieldVal['md']);
      }
    }
  }
};

export const runPostSaveConditions = async (
  field,
  createdRecord,
  adminAssetApi
) => {
  // HANDLE ASSET FIELD
  // Check if the asset ID matches
  if (checkIfAssetId(field)) {
    const assetId = field;
    // created instances come back as an array, so get the first one
    const recordId = createdRecord.data[0].id;

    console.log(`updating asset ${assetId} with {entityId}: ${recordId}`);

    await adminAssetApi.updateAsset(assetId, { entityId: recordId });
  }
};

const checkIfAssetId = (data: any) => {
  // console.error(id);
  // Is it actually a string?
  if (!data) {
    return false;
  }

  if (data.id && data.key) {
    // Is it a UUID?
    if (
      !data.id.match(
        '^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$'
      )
    ) {
      return false;
    }

    return true;
  } else {
    return false;
  }
};
