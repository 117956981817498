import { Accordion, AccordionSummary, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AdminCard from '../../../components/AdminCard';
import BeaconSettingForm from './beaconSettings/BeaconSettingForm';
import TrackerSelectorForm from './trackerSettings/TrackerSelectorForm';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  InnerAccordian: {
    padding: 10,
    width: '100%',
    backgroundColor: '#FAFAFA',
  },
  TrackerSwitch: {
    '& .Mui-checked': {
      color: 'primary',
      transform: 'translateX(25px) !important',
    },
  },
}));

const BeaconGridSettingsCard = ({
  title = undefined,
  children,
  selectable = false,
  onSelect,
  onFormChange,
  id,
  form_data,
  enabled,
  beacon,
  showSettings,
  disableToggle,
  cardType,
}: any) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(enabled ?? false);
  }, [enabled]);

  const stopExpand = (event) => {
    event.stopPropagation();
  };

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
    onSelect(id, event.target.checked);
    event.stopPropagation();
  };

  const getTitle = () => (
    <>
      {title}
      {selectable}
    </>
  );

  const TrackerInnerAccordion = () => (
    <>
      {checked && (
        <Accordion className={classes.InnerAccordian} expanded={true}>
          <AccordionSummary aria-controls='panel1bh-content'>
            <Typography variant='h6' gutterBottom component='div'>
              Settings
            </Typography>
          </AccordionSummary>
          <TrackerSelectorForm
            onFormChange={onFormChange}
            id={id}
            form_data={form_data}
            beacon={beacon}
          ></TrackerSelectorForm>
        </Accordion>
      )}
    </>
  );

  const BeaconSettingInnerAccordian = () => (
    <>
      <Accordion className={classes.InnerAccordian} expanded={true}>
        <AccordionSummary aria-controls='panel1bh-content'>
          <Typography variant='h6' gutterBottom component='div'>
            Settings
          </Typography>
        </AccordionSummary>
        <BeaconSettingForm
          onFormChange={onFormChange}
          id={id}
          form_data={form_data}
          beacon={beacon}
        ></BeaconSettingForm>
      </Accordion>
    </>
  );

  return (
    <AdminCard
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {onSelect && (
            <Switch
              disabled={disableToggle}
              className={classes.TrackerSwitch}
              checked={checked}
              onChange={handleSwitchChange}
              onClick={stopExpand}
            />
          )}
          <span>&nbsp;{getTitle()}</span>
        </div>
      }
    >
      {children}
      {showSettings && cardType === 'Tracker' && (
        <TrackerInnerAccordion></TrackerInnerAccordion>
      )}
      {showSettings && cardType === 'Beacon' && <BeaconSettingInnerAccordian />}
    </AdminCard>
  );
};

export default BeaconGridSettingsCard;
