import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const AutoCompleteWidget = (props) => {
  return (
    <>
      <Autocomplete
        options={props.options.enumOptions}
        getOptionLabel={(option: any) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            variant='outlined'
            required={props.required}
          />
        )}
        onChange={(_event, value) => {
          props.onChange(value.value);
        }}
      />
    </>
  );
};

export default AutoCompleteWidget;
