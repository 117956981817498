import { Container, Typography } from '@mui/material';
import AdminCard from './AdminCard';

const AdminSupportSidebar = ({ title = '', children }) => (
  <AdminCard title={title}>
    <Container
      style={{ backgroundColor: '#eee', paddingTop: 20, paddingBottom: 20 }}
    >
      <Typography style={{ fontSize: '.8rem' }}>{children}</Typography>
    </Container>
  </AdminCard>
);

export default AdminSupportSidebar;
