import AdminPage from '../../components/AdminPage';
import { Link } from 'react-router-dom';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { Chip, TableCell } from '@mui/material';
import { Link as UiLink } from '@mui/material';
import { AdminTableRow } from '../../components/AdminTable';

const AssetList = () => (
  <AdminPage animation={true} title='Jobs' newAction='/jobs/new'>
    <AdminPaginatedList
      columns={['title', 'run time', 'actions']}
      url='/admin/schedules'
      itemTemplate={itemTemplate}
      filterKeys={[]}
    />
  </AdminPage>
);

const getRunTime = (interval, cron) => (
  <>
    {interval && <td>Interval: {interval}</td>}
    {cron && <td>Cron: {cron}</td>}
  </>
);

const getChip = (status) => (
  <>
    {status === 'active' && <Chip color='primary' label={status} />}
    {status !== 'active' && <Chip color='default' label={status} />}
  </>
);

const itemTemplate = (
  { status, id, name, interval, cron, updatedAt },
  index
) => (
  <AdminTableRow key={index}>
    <TableCell>
      <UiLink component={Link} to={`/jobs/${id}`}>
        {name}
      </UiLink>
    </TableCell>
    <TableCell>{getRunTime(interval, cron)}</TableCell>
    <TableCell>{getChip(status)}</TableCell>
  </AdminTableRow>
);

export default AssetList;
