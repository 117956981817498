import { Button, Table, TableBody, TableCell } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminAssetViewer from '../../../components/AdminAssetViewer';
import AdminCard from '../../../components/AdminCard';
import AdminPage from '../../../components/AdminPage';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import {
  AdminTableHeadCell,
  AdminTableRow,
} from '../../../components/AdminTable';

const ContentRecordDetail = () => {
  const { collectionId, recordId } =
    useParams<{ collectionId: string; recordId: string }>();
  const { jwtToken, adminContentApi } = useBlueprintAdmin();
  const [record, setRecord] = useState<any>(undefined);
  const [srcImageUrl, setSrcImageUrl] = useState<any>(undefined);

  const getFields = (content) => {
    return Object.entries(content);
  };

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    if (collectionId && recordId) {
      adminContentApi
        ?.getCollectionRecord(collectionId, recordId)
        .then(({ data }) => {
          setRecord(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [collectionId, recordId, jwtToken]);

  return (
    <>
      {recordId && (
        <AdminPage
          animation={true}
          editAction={`/content/collections/${collectionId}/records/${recordId}/edit`}
          title={`Record: ${recordId}`}
        >
          <AdminCard>
            {record && (
              <>
                <Table>
                  <TableBody>
                    {getFields(record.content).map((item: any, i) => (
                      <AdminTableRow key={i}>
                        <AdminTableHeadCell>{item[0]}</AdminTableHeadCell>
                        <TableCell>{JSON.stringify(item[1])}</TableCell>
                      </AdminTableRow>
                    ))}
                  </TableBody>
                </Table>
                {record.content['asset'] && (
                  <>
                    <AdminAssetViewer
                      id={record.content['asset']}
                      setImageSrcUrl={setSrcImageUrl}
                    />
                    {srcImageUrl && (
                      <a href={srcImageUrl} target='_blank' rel='noreferrer'>
                        <Button variant='contained' color='primary'>
                          Download
                        </Button>
                      </a>
                    )}
                  </>
                )}
              </>
            )}
          </AdminCard>
        </AdminPage>
      )}
    </>
  );
};

export default ContentRecordDetail;
