import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { OptionGroup } from 'react-querybuilder';

export type ValueEditorType =
  | 'text'
  | 'select'
  | 'checkbox'
  | 'radio'
  | 'textarea'
  | 'switch'
  | null;

export interface CommonProps {
  /**
   * CSS classNames to be applied
   */
  className?: string;
  /**
   * The level of the current group
   */
  level: number;
  /**
   * The title for this control
   */
  title?: string;
  /**
   * Container for custom props that are passed to all components
   */
  context?: any;
}

export interface NameLabelPair {
  name?: string;
  label: string;
  [x: string]: any;
}

export interface NotToggleProps extends CommonProps {
  checked?: boolean;
  handleOnChange(checked: boolean): void;
}

export interface ValueEditorProps extends SelectorEditorProps {
  field: string;
  fieldData: Field;
  operator: string;
  type?: ValueEditorType;
  inputType?: string | null;
  values?: Array<any>;
  value?: any;
}

export interface Field extends NameLabelPair {
  id?: string;
  operators?: Array<NameLabelPair>;
  valueEditorType?: ValueEditorType;
  inputType?: string | null;
  values?: Array<NameLabelPair>;
  defaultValue?: any;
  [x: string]: any;
  placeholder?: string;
}

export interface SelectorEditorProps extends CommonProps {
  value?: string;
  handleOnChange(value: any): void;
}

export interface ActionProps extends CommonProps {
  label?: string;
  handleOnClick(e: React.MouseEvent): void;
}

export interface ValueSelectorProps extends SelectorEditorProps {
  options: Field[] | OptionGroup[];
}

export const MaterialActionElement = ({
  className,
  handleOnClick,
  label,
  title,
}: ActionProps) => (
  <Button
    variant='outlined'
    color='primary'
    className={className}
    title={title}
    onClick={(e) => handleOnClick(e)}
  >
    {label}
  </Button>
);

export const MaterialValueSelector = ({
  className,
  handleOnChange,
  options,
  value,
  title,
}: ValueSelectorProps) => (
  <FormControl
    variant='outlined'
    style={{ margin: 10 }}
    className={className}
    title={title}
  >
    <Select value={value} onChange={(e) => handleOnChange(e.target.value)}>
      {options.map((option) => {
        const key = option.id ? `key-${option.id}` : `key-${option.name}`;
        return (
          <MenuItem key={key} value={option.name}>
            {option.label}
          </MenuItem>
        );
      })}
    </Select>
  </FormControl>
);

export const MaterialNotToggle = ({
  className,
  handleOnChange,
  checked,
}: NotToggleProps) => (
  <FormControlLabel
    className={className}
    control={
      <Checkbox
        checked={!!checked}
        onChange={(e) => handleOnChange(e.target.checked)}
      />
    }
    label='Not'
  />
);

export const MaterialValueEditor = ({
  operator,
  value,
  handleOnChange,
  title,
  className,
  type,
  inputType,
  values,
}: ValueEditorProps) => {
  if (operator === 'null' || operator === 'notNull') {
    return null;
  }

  switch (type) {
    case 'select':
      return (
        <FormControl variant='outlined' className={className}>
          <Select
            style={{ margin: 10 }}
            value={value}
            onChange={(e) => handleOnChange(e.target.value)}
          >
            {values &&
              values.map((v) => (
                <MenuItem key={v.name} value={v.name}>
                  {v.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );

    case 'checkbox':
      return (
        <Checkbox
          style={{ margin: 10 }}
          className={className}
          onChange={(e) => handleOnChange(e.target.checked)}
          checked={!!value}
        />
      );

    case 'radio':
      return (
        <FormControl
          variant='outlined'
          style={{ margin: 10 }}
          className={className}
          title={title}
          component='fieldset'
        >
          <RadioGroup
            value={value}
            onChange={(e) => handleOnChange(e.target.value)}
          >
            {values?.map((v) => (
              <FormControlLabel
                key={v.name}
                value={v.name}
                control={<Radio />}
                label={v.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );

    default:
      return (
        <TextField
          label={title}
          variant='outlined'
          style={{ margin: 10 }}
          type={inputType || 'text'}
          value={value}
          title={title}
          className={className}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      );
  }
};
