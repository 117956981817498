import { toast } from 'material-react-toastify';

export const toastWarning = (message) => {
  toast.warning(message, { position: 'top-center', autoClose: 2000 });
};

export const toastError = (message) => {
  toast.error(message, { position: 'top-center', autoClose: 2000 });
};

export const toastSuccess = (message) => {
  toast.success(message, { position: 'top-center', autoClose: 2000 });
};

export const toastInfo = (message) => {
  toast.info(message, { position: 'top-center', autoClose: 6000 });
};
