import { Paper } from '@mui/material';

const AdminFormCallout = ({ children }) => (
  <Paper
    elevation={0}
    style={{
      margin: 20,
      padding: 10,
      height: '100%',
      backgroundColor: '#fffde7',
      borderColor: '#ccc',
      borderWidth: '5',
      borderRadius: '0',
    }}
  >
    {children}
  </Paper>
);

export default AdminFormCallout;
