import { Typography } from '@mui/material';
import { AdminToolTip } from '../../../../components/AdminToolTip';
import { uuidv4 } from '../../../../utils/uuid';
export const DefaultBeaconSettingOptions = [
  {
    id: 'configuration',
    unique_id: uuidv4(),
    title: 'Configuration',
    description: 'This allows you to change the beacon configuration settings',
    form_data: {
      dynamicConfigCacheDuration: '',
    },
    tooltip: (
      <AdminToolTip
        message={
          <Typography variant='caption'>
            placeholder text for what we want to display for info
          </Typography>
        }
      ></AdminToolTip>
    ),
  },
  {
    id: 'store',
    unique_id: uuidv4(),
    title: 'Store',
    description: 'This allows you to change the beacon Store settings',
    form_data: {
      devQueue: '',
    },
    tooltip: (
      <AdminToolTip
        message={
          <Typography variant='caption'>
            placeholder text for what we want to display for info
          </Typography>
        }
      ></AdminToolTip>
    ),
  },
  {
    id: 'activity',
    unique_id: uuidv4(),
    title: 'Activity',
    description: 'This allows you to change the beacon Activty settings',
    form_data: {
      devQueueMaxSize: '',
    },
    tooltip: (
      <AdminToolTip
        message={
          <Typography variant='caption'>
            placeholder text for what we want to display for info
          </Typography>
        }
      ></AdminToolTip>
    ),
  },
];

export interface BeaconFormFields {
  devQueueMaxSize: string;
  dynamicConfigCacheDuration: string;
  devQueue: string;
}

export const BeaconFormDefaultValues = {
  devQueueMaxSize: '',
  dynamicConfigCacheDuration: '',
  devQueue: '',
};

export interface BeaconSettingAccordian {
  title?: any;
  children?: any;
  selectable?: boolean;
  onSelect?: any;
  onFormChange?: any;
  id?: string;
  form_data?: any;
  enabled?: boolean;
  beacon?: any;
  showSettings?: boolean;
  onSelectSpecific?: any;
  disableToggle?: boolean;
}
