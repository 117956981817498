import {
  Grid,
  Link as UiLink,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import AdminCard from '../../../components/AdminCard';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import { AdminTableHead, AdminTableRow } from '../../../components/AdminTable';
import { AdminToolTip } from '../../../components/AdminToolTip';
const TriggerRunHistoryTemplate = ({ id, showUser }) => {
  const { adminTriggerApi } = useBlueprintAdmin();

  const [history, setHistory] = useState<any>([]);

  useEffect(() => {
    adminTriggerApi!
      .getRunHistory(id)
      .then((data) => {
        if (data.success) setHistory(data.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <>
      {history && (
        <Grid>
          <AdminCard
            title={
              <>
                Run History
                <AdminToolTip
                  message={
                    <Typography variant='caption'>
                      {
                        'Actions are the steps that will be performed on a web experience when conditions are met. Actions are performed when a trigger is fired.'
                      }
                    </Typography>
                  }
                ></AdminToolTip>
              </>
            }
          >
            <Table>
              <AdminTableHead>
                <TableRow>
                  {showUser && <TableCell>User ID</TableCell>}
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Logs</TableCell>
                </TableRow>
              </AdminTableHead>
              <TableBody>
                {history.map((record) => (
                  <AdminTableRow key={record.id}>
                    {showUser && (
                      <TableCell>
                        <UiLink
                          component={Link}
                          key={record.userId}
                          to={`/users/${record.userId}`}
                        >
                          {record.userId}
                        </UiLink>
                      </TableCell>
                    )}
                    <TableCell>
                      <Moment fromNow>{record.createdAt}</Moment>
                    </TableCell>
                    <TableCell>
                      <pre>
                        {JSON.stringify(JSON.parse(record.logs), null, 2)}
                      </pre>
                    </TableCell>
                  </AdminTableRow>
                ))}
              </TableBody>
            </Table>
          </AdminCard>
        </Grid>
      )}
    </>
  );
};

export default TriggerRunHistoryTemplate;
