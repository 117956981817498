import { useParams } from 'react-router-dom';
import AdminPage from '../../../components/AdminPage';
import UserGroupTemplate from '../../../components/templates/GroupDetail';

const UserGroupDetail = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      {id && (
        <AdminPage
          title={`User Group Detail`}
          editAction={`/users/group/${id}/edit`}
        >
          <UserGroupTemplate id={id} />
        </AdminPage>
      )}
    </>
  );
};

export default UserGroupDetail;
