import AdminPage from '../../../components/AdminPage';
import { Link } from 'react-router-dom';
import AdminPaginatedList from '../../../components/AdminPaginatedList';
import { Link as UiLink, Grid, TableCell } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { AdminTableRow } from '../../../components/AdminTable';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    paddingBottom: theme.spacing(2),
  },
}));

const ConfigNotificationTemplateList = () => {
  const classes = useStyles();

  return (
    <AdminPage
      animation={true}
      title='Notification Templates'
      newAction={`/config/notification-template/new`}
    >
      <Grid className={classes.card}>
        <AdminPaginatedList
          title='Notification Templates'
          columns={['id', 'updatedAt']}
          url='/admin/config/notification-template'
          itemTemplate={configTemplate}
        />
      </Grid>
    </AdminPage>
  );
};

const configTemplate = ({ id, updatedAt }, index) => (
  <AdminTableRow key={index}>
    <TableCell>
      <UiLink component={Link} to={`/config/notification-template/${id}`}>
        {id}
      </UiLink>
    </TableCell>
    <TableCell>Updated {new Date(updatedAt).toLocaleString()}</TableCell>
  </AdminTableRow>
);

export default ConfigNotificationTemplateList;
