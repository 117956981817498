import MonacoEditor from '@monaco-editor/react';
import { Avatar, Chip, Link as UiLink, Table, TableBody } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { BrowserIcon, DeviceIcon } from '../../utils/icons';
import AdminCard from '../AdminCard';
import AdminModal from '../AdminModal';
import { useBlueprintAdmin } from '../AdminProvider';
import AdminRecord from '../AdminRecord';
import {
  AdminTableHead,
  AdminTableHeadCell,
  AdminTableRow,
} from '../AdminTable';

const UserDevices = ({ id }) => {
  const { jwtToken, config } = useBlueprintAdmin();
  const [isModalOpen, setIsModalOpen] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<any>();

  const openModal = (data) => {
    setSelectedItem(data);
    setIsModalOpen(true);
  };

  const closeModal = (data) => {
    setIsModalOpen(false);
  };

  const modalTitle = () => {
    if (selectedItem) {
      return selectedItem.id;
    } else {
      return 'Activity';
    }
  };

  const modalTemplate = () => (
    <>
      {selectedItem && (
        <MonacoEditor
          theme='vs-dark'
          defaultLanguage='json'
          value={JSON.stringify(selectedItem, null, 2)}
          height='100vh'
          width='100%'
        />
      )}
    </>
  );

  const deviceTemplate = (title, user) => (
    <>
      {user.devices && user.devices.length > 0 && (
        <AdminCard title={title}>
          <Table>
            <AdminTableHead>
              <TableRow>
                <AdminTableHeadCell>device</AdminTableHeadCell>
                <AdminTableHeadCell>browser</AdminTableHeadCell>
                <AdminTableHeadCell>ip</AdminTableHeadCell>
                <AdminTableHeadCell>actions</AdminTableHeadCell>
              </TableRow>
            </AdminTableHead>
            <TableBody>
              {user.devices.map((device: any) => (
                <AdminTableRow key={device.id}>
                  <TableCell>
                    <Chip
                      size='medium'
                      variant='outlined'
                      color='primary'
                      avatar={
                        <Avatar>
                          <DeviceIcon
                            style={{ padding: 2 }}
                            type={device.components.useragent.os.family}
                            fontSize='small'
                          />
                        </Avatar>
                      }
                      label={`${device.components.useragent.os.family} ${device.components.useragent.os.major}`}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      color='primary'
                      variant='outlined'
                      avatar={
                        <Avatar>
                          <BrowserIcon
                            style={{ padding: 2 }}
                            type={device.components.useragent.browser.family}
                            fontSize='large'
                          />
                        </Avatar>
                      }
                      label={`${device.components.useragent.browser.family} ${device.components.useragent.browser.version}`}
                    />
                  </TableCell>
                  <TableCell>{device.components.clientIp}</TableCell>
                  <TableCell>
                    <UiLink
                      component={Link}
                      to='#'
                      color='primary'
                      onClick={() => openModal(device)}
                    >
                      View
                    </UiLink>
                  </TableCell>
                  {/* <td><pre style={{fontSize: "0.2rem"}}>{JSON.stringify(device.components, null, 2)}</pre></td> */}
                </AdminTableRow>
              ))}
            </TableBody>
          </Table>
        </AdminCard>
      )}
    </>
  );

  return (
    <>
      <AdminRecord
        loadingTemplate={deviceTemplate('Devices', {})}
        form={true}
        url={`/admin/users/${id}/devices`}
        itemTemplate={deviceTemplate.bind(null, 'Devices')}
      />
      <AdminModal
        title='Device Fingerprint'
        body={modalTemplate()}
        isOpen={isModalOpen}
        closeModal={closeModal}
        size='xl'
      />
    </>
  );
};

export default UserDevices;
