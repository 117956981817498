import { Box, Button, FormControl, TextField } from '@mui/material';
import { useFormik } from 'formik';

interface RegisterFormProps {
  handleSubmit: (username: string, password: string) => void;
}

const RegisterForm = ({ handleSubmit }: RegisterFormProps) => {
  const validate = (values) => {
    const errors: any = {};
    if (!values.username) {
      errors.username = 'Please enter a username';
    }
    if (!values.password) {
      errors.password = 'Please enter a password';
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      confirmPassword: '',
    },
    // eslint-disable-next-line
    onSubmit: async (values) => {
      handleSubmit(values.username, values.password);
    },
    validate,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box maxWidth={{ xs: 400 }} my={2}>
        <FormControl margin='dense' fullWidth>
          <TextField
            id='username'
            name='username'
            type='text'
            variant='outlined'
            label='Username'
            autoComplete='username email'
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.errors.username !== undefined}
            helperText={formik.errors.username}
          />
        </FormControl>
        <FormControl margin='dense' fullWidth>
          <TextField
            id='password'
            name='password'
            type='password'
            variant='outlined'
            label='Password'
            autoComplete='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.errors.password !== undefined}
            helperText={formik.errors.password}
          />
        </FormControl>
        <FormControl margin='dense' fullWidth>
          <TextField
            id='confirmPassword'
            name='confirmPassword'
            type='password'
            variant='outlined'
            label='Confirm Password'
            autoComplete='confirmPassword'
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.errors.confirmPassword !== undefined}
            helperText={formik.errors.confirmPassword}
          />
        </FormControl>
      </Box>
      <Button
        variant='contained'
        color='primary'
        size='large'
        id='submit'
        type='submit'
        fullWidth
      >
        CREATE ACCOUNT
      </Button>
    </form>
  );
};

export default RegisterForm;
