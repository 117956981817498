import { TableCell } from '@mui/material';
import { useState } from 'react';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { AdminTableRow } from '../AdminTable';

const UserDetailForm = ({ user, id, title }) => {
  const [isLoading, setIsLoading] = useState(true);

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const formatValue = (value) => {
    if (IsJsonString(value) && typeof value !== 'string') {
      const parsedValue = JSON.parse(value);
      const values: Array<any> = [];

      // its an object, so parse it out
      Object.keys(parsedValue).forEach(function (key, index) {
        values.push({ key: key, value: parsedValue[key].toString() });
      });

      return {
        type: 'array',
        value: values,
      };
    } else {
      // its just a string, push it out
      return {
        type: 'string',
        value: value,
      };
    }
  };

  const renderValue = (value) => (
    <>
      {formatValue(value).type === 'string' && <p>{value}</p>}
      {formatValue(value).type === 'array' && (
        <>
          {formatValue(value).value.map((item: any) => (
            <>
              <span key={item.key}>
                <strong>{item.key}:</strong> {item.value}
              </span>
              <br />
            </>
          ))}
        </>
      )}
    </>
  );

  const itemTemplate = ({ field, value, source }, index) => (
    <AdminTableRow key={index}>
      <TableCell>{field}</TableCell>
      <TableCell>{renderValue(value)}</TableCell>
      <TableCell>{source}</TableCell>
    </AdminTableRow>
  );

  return (
    <AdminPaginatedList
      title='Traits'
      url={`/admin/users/${id}/profile`}
      columns={['field', 'value', 'source']}
      itemTemplate={itemTemplate}
      showCard={false}
      infiniteLoad={true}
      pageSize={50}
    />
  );
};

export default UserDetailForm;
