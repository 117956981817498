import { Chip, Grid, TableCell, Tooltip, Link as UiLink } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';
import AdminPage from '../../components/AdminPage';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { AdminTableRow } from '../../components/AdminTable';
import { AdminToastTimed } from '../../components/AdminToasts';
import { uuidv4 } from '../../utils/uuid';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    paddingBottom: theme.spacing(2),
  },
}));

const getChip = (status) => (
  <>
    {status === 'active' && <Chip color='primary' label={status} />}
    {status !== 'active' && <Chip color='default' label={status} />}
  </>
);

const AdminList = () => {
  const classes = useStyles();
  const location: any = useLocation();
  return (
    <>
      {location.state?.detail === 'newAdminGroup' && (
        <AdminToastTimed
          message={'Admin group successfully created'}
          trigger={location.state?.detail === 'newAdminGroup' ? uuidv4() : null}
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}
      <AdminPage animation={true} title='Admin Dashboard'>
        <Grid className={classes.card}>
          <div data-testid='AdminNewGroup'>
            <AdminPaginatedList
              title='Admin Groups'
              columns={['name', 'description', 'roles', 'actions']}
              url='/admin/groups'
              itemTemplate={groupTemplate}
              filterKeys={['name', 'status']}
              newAction='/admins/group/new'
            />
          </div>
        </Grid>
        <Grid className={classes.card}>
          <div data-testid='AdminNewServiceAccount'>
            <AdminPaginatedList
              title='Admin Service Accounts'
              url='/admin/admins/type/service'
              columns={['username', 'status', 'actions']}
              itemTemplate={serviceTemplate}
              filterKeys={['username', 'status']}
              newAction='/admins/service/new'
            />
          </div>
        </Grid>
        <Grid className={classes.card}>
          <div data-testid='AdminNewUser'>
            <AdminPaginatedList
              title='Admin Users'
              url='/admin/admins/type/user'
              columns={['username', 'status', 'actions']}
              itemTemplate={userTemplate}
              filterKeys={['username', 'status']}
              newAction='/admins/new'
            />
          </div>
        </Grid>
      </AdminPage>
    </>
  );
};

const groupTemplate = (
  { status, id, name, description, roles, updatedAt },
  index
) => (
  <AdminTableRow key={index}>
    <TableCell>
      <UiLink component={Link} to={`/admins/group/${id}`}>
        {name}
      </UiLink>
    </TableCell>
    <TableCell>{description}</TableCell>
    <TableCell>
      <Tooltip title={roles.join(', ')}>
        <UiLink color='inherit'>
          {''.concat(
            roles.join(', ').substring(0, 25),
            roles.join(', ').length > 25 ? '...' : ''
          )}
        </UiLink>
      </Tooltip>
    </TableCell>
    <TableCell>
      <UiLink component={Link} to={`/admins/group/${id}`}>
        View
      </UiLink>
    </TableCell>
  </AdminTableRow>
);

const serviceTemplate = ({ status, id, username, updatedAt }, index) => (
  <AdminTableRow key={index}>
    <TableCell>
      <UiLink component={Link} to={`/admins/service/${id}`}>
        {username}
      </UiLink>
    </TableCell>
    <TableCell>{getChip(status)}</TableCell>
    <TableCell>Updated {new Date(updatedAt).toLocaleString()}</TableCell>
  </AdminTableRow>
);

const userTemplate = ({ status, id, username, updatedAt }, index) => (
  <AdminTableRow key={index}>
    <TableCell>
      <UiLink component={Link} to={`/admins/${id}`}>
        {username}
      </UiLink>
    </TableCell>
    <TableCell>{getChip(status)}</TableCell>
    <TableCell>Updated {new Date(updatedAt).toLocaleString()}</TableCell>
  </AdminTableRow>
);

export default AdminList;
