export const traits = [
  'userAgent',
  'ipAddress',
  'email',
  'phone',
  'gender',
  'dateOfBirth',
  'lastName',
  'firstName',
  'city',
  'state',
  'zipCode',
  'countryCode',
];

export const defaultSelectedPIITraits = ['userAgent', 'ipAddress'];
