import { Chip, Grid, TableCell, Link as UiLink } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AdminPage from '../../components/AdminPage';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { AdminTableRow } from '../../components/AdminTable';
import { AdminToastTimed } from '../../components/AdminToasts';
import { uuidv4 } from '../../utils/uuid';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    paddingBottom: theme.spacing(2),
  },
}));

const TriggerList = () => {
  const classes = useStyles();
  const [toastController, setToastController]: any = useState(null);
  const location: any = useLocation();
  useEffect(() => {
    setToastController(location.state?.detail ?? '');
  }, [location.state?.detail]);
  return (
    <>
      {toastController?.includes('newTrigger') && (
        <AdminToastTimed
          message={'Trigger successfully created'}
          trigger={toastController?.includes('newTrigger') ? uuidv4() : null}
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}
      <AdminPage animation={true} title='Triggers' newAction={`/triggers/new`}>
        <Grid className={classes.card}>
          <AdminPaginatedList
            title='Activity Triggers'
            columns={['Title', 'Status', 'Beacon', 'Actions']}
            url={`/admin/triggers/lookup/active/activity`}
            emptyTemplate={emptyTemplate}
            itemTemplate={itemTemplate}
          />
        </Grid>
        <Grid className={classes.card}>
          <AdminPaginatedList
            title='Profile Triggers'
            columns={['Title', 'Status', 'Beacon', 'Actions']}
            url={`/admin/triggers/lookup/active/profile`}
            emptyTemplate={emptyTemplate}
            itemTemplate={itemTemplate}
          />
        </Grid>
        <Grid className={classes.card}>
          <AdminPaginatedList
            title='Content Triggers'
            columns={['Title', 'Status', 'Beacon', 'Actions']}
            url={`/admin/triggers/lookup/active/content`}
            emptyTemplate={emptyTemplate}
            itemTemplate={itemTemplate}
          />
        </Grid>
      </AdminPage>
    </>
  );
};

const emptyTemplate = () => (
  <span>
    There are currently no triggers,{' '}
    <UiLink component={Link} to='/triggers/new'>
      create a new one
    </UiLink>
    .
  </span>
);

const getChip = (status) => (
  <>
    {status === 'active' && <Chip color='primary' label={status} />}
    {status !== 'active' && <Chip color='default' label={status} />}
  </>
);

const itemTemplate = ({ status, id, name, beaconId, updatedAt }, index) => (
  <AdminTableRow key={index}>
    <TableCell>
      <UiLink component={Link} to={`/triggers/${id}`}>
        {name}
      </UiLink>
    </TableCell>
    <TableCell>{getChip(status)}</TableCell>
    <TableCell>{beaconId}</TableCell>
    <TableCell>Updated {new Date(updatedAt).toLocaleString()}</TableCell>
  </AdminTableRow>
);

export default TriggerList;
