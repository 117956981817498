// USE getEnv() TO GET ALL ENV VARIABLES
// USE getEnv("MY_VAR") TO GET REACT_APP_MY_VAR
export const getEnv = (key?: string) => {
  if (!window['__ENV']) {
    throw new Error('No __ENV key exists on the window object!');
  }
  if (key && key.length) {
    return window['__ENV'][key] || null;
  }
  return window['__ENV'];
};

export default getEnv;
