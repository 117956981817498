import { Grid, Typography } from '@mui/material';
import { AdminToolTip } from '../../../../../components/AdminToolTip';
import { FormInputText } from '../../form-components/FormInputText';
import { DefaultBeaconSettingOptions } from '../BeaconSettingConstants';
export const setStoreConfig = (savedData, name) => {
  const storeSettings = savedData.store;
  const form_data_obj: any = {
    ...DefaultBeaconSettingOptions.find((element) => {
      return element.id === name;
    })?.form_data,
  };
  if (storeSettings.devQueue) {
    form_data_obj.devQueue = storeSettings.devQueue;
  }
  return form_data_obj;
};

export const userStoreSetting = (form_data) => {
  if (form_data.devQueue === '') {
    return null;
  } else {
    return { devQueue: form_data.devQueue };
  }
};

export const setStoreSubmit = (form_data, updatedData) => {
  form_data.devQueue = updatedData.devQueue;
};

export const StoreSettingForm = (control, setValue, onSubmit, form_data) => {
  return (
    <Grid container spacing={1}>
      <p style={{ marginTop: 0, marginBottom: 0 }}>
        How large you want the dev que to get before sending data{' '}
        <AdminToolTip
          message={
            <Typography variant='caption'>
              placeholder text for what we want to display for info
            </Typography>
          }
        ></AdminToolTip>
      </p>
      <Grid item spacing={1} sm={12}>
        <FormInputText
          name='devQueue'
          control={control}
          label='Number of queue items'
          defaultValue={form_data.devQueue}
          setValue={setValue}
          submitfunction={onSubmit}
          errorCheck={'integerCheck'}
          testId={'store-input'}
        />
      </Grid>
    </Grid>
  );
};
export {};
