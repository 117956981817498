import { Container, Grid, withStyles } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

const AdminTab = Tab;

// TODO: STYLEFIX
// const AdminTab = withStyles((theme) => ({
//   root: {
//     fontSize: '.9rem',
//     textAlign: 'center',
//     backgroundColor: theme.palette.common.white,
//   },
//   wrapper: {
//     alignItems: 'flex-center',
//     paddingTop: theme.spacing(0),
//     paddingLeft: theme.spacing(1),
//     paddingRight: theme.spacing(1),
//     paddingBottom: theme.spacing(0),
//   },
//   selected: {
//     color: theme.palette.primary.main,
//   },
// }))(Tab);

const AdminTabsUnboxed = ({
  tabs,
  removeTitle = false,
  defaultTab = 1, // index of the default tab that is shown
  ...props
}: {
  title?: string;
  tabs: Array<{
    title: string;
    template: React.ReactNode;
    icon?: React.ReactNode;
  }>;
  props?: {};
  removeTitle?: any;
  defaultTab?: number;
}) => {
  const [value, setValue] = useState(defaultTab);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const titleTab = () => (
    <Tabs
      variant='scrollable'
      allowScrollButtonsMobile
      style={{ overflow: 'hidden', minWidth: '0px' }}
      value={value}
      onChange={handleChange}
      {...props}
    >
      {tabs.map(({ title }, i) => (
        <AdminTab
          style={{ overflow: 'hidden', minWidth: '0px' }}
          label={title}
          key={i}
        />
      ))}
    </Tabs>
  );

  const normalTabs = () => (
    <>
      {tabs.map(({ template }, i) => {
        return (
          (!removeTitle ? value - 1 : value) === i && (
            <Container style={{ padding: 0 }} key={i} role='tabpanel'>
              <>{template}</>
            </Container>
          )
        );
      })}
    </>
  );

  return (
    <Grid container>
      <Grid item xs={12} justifyContent='center' container direction='row'>
        {titleTab()}
      </Grid>
      <Grid container item xs={12}>
        {normalTabs()}
      </Grid>
    </Grid>
  );
};

export default AdminTabsUnboxed;
