export const uiSchema = {};

export const schema = {
  description: 'Creating an invite code for new users',
  type: 'object',
  properties: {
    invite_email: {
      type: 'string',
      title: 'Email',
      description: 'An email can be entered to invite the user to join',
    },
  },
};
