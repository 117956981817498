import AdminPage from '../../components/AdminPage';

const UserExport = () => {
  // const { config, adminActivityApi, adminUserApi } = useBlueprintAdmin();

  // const [records, setRecords] = useState<Array<any> | undefined>(undefined);

  // const today = new Date();

  // const fileName = `activity_export_${today.toISOString().substring(0, 10)}`;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const users: Map<any, any> = new Map();

  //     // 2. Get activity for each user
  //     const activityData = await adminActivityApi.getOrderedAdminActivity();
  //     const data: Array<any> = [];

  //     // 3. Loop through all of these
  //     for (const record of activityData.data) {
  //       // if (record.context.hostname === "www.lillypulitzer.com") {

  //       if (!users.has(record.userId)) {
  //         // 1. Get the user for the activity
  //         const userResponse = await adminUserApi!.getUser(record.userId);
  //         const user = userResponse.data;

  //         // @TODO go fill this in later
  //         user.traits = [];

  //         // 2. Get their device information
  //         const deviceResponse = await adminUserApi!.getUserDevices(user.id);
  //         let userDevice = {};

  //         if (deviceResponse.data && deviceResponse.data.devices) {
  //           userDevice = deviceResponse.data.devices[0];
  //           user.device = userDevice;
  //         }

  //         users.set(record.userId, user);
  //       }

  //       const cachedUser = users.get(record.userId);

  //       const varFirstName = cachedUser.traits.filter(function (trait) {
  //         return trait.key === 'firstName';
  //       });

  //       const firstName =
  //         varFirstName.length > 0 ? varFirstName[0].value : null;

  //       const varLastName = cachedUser.traits.filter(function (trait) {
  //         return trait.key === 'lastName';
  //       });

  //       const lastName = varLastName.length > 0 ? varLastName[0].value : null;

  //       const varAddress = cachedUser.traits.filter(function (trait) {
  //         return trait.key === 'address';
  //       });

  //       const address = varAddress.length > 0 ? varAddress[0].value : null;

  //       const varEmail = cachedUser.traits.filter(function (trait) {
  //         return trait.key === 'email';
  //       });

  //       const email = varEmail.length > 0 ? varEmail[0].value : null;

  //       const varPhoneNumber = cachedUser.traits.filter(function (trait) {
  //         return trait.key === 'phoneNumber';
  //       });

  //       const phoneNumber =
  //         varPhoneNumber.length > 0 ? varPhoneNumber[0].value : null;

  //       const varCustomerId = cachedUser.traits.filter(function (trait) {
  //         return trait.key === 'customerId';
  //       });

  //       const customerId =
  //         varCustomerId.length > 0 ? varCustomerId[0].value : null;

  //       data.push({
  //         identity: {
  //           blueprintId: cachedUser.id,
  //           externalId: cachedUser.external_id,
  //           externalSource: cachedUser.external_source,
  //           customerId: customerId,
  //         },
  //         traits: {
  //           ip: cachedUser.device.components.clientIp,
  //           firstName: firstName,
  //           lastName: lastName,
  //           address: address,
  //           email: email,
  //           phoneNumber: phoneNumber,
  //         },
  //         device: {
  //           id: cachedUser.device.id,
  //           hash: cachedUser.device.hash,
  //           components: cachedUser.device.components,
  //         },
  //         activity: {
  //           id: record.id,
  //           hostname: record.context.hostname ? record.context.hostname : '',
  //           channel: record.channel,
  //           event: record.event,
  //           context: record.context,
  //           properties: record.properties,
  //           type: record.type,
  //           entityType: record.entityType,
  //           entityId: record.entityId,
  //           entityProps: record.entityProps,
  //           originalTimestamp: record.originalTimestamp,
  //           batchId: record.batchId,
  //         },
  //       });
  //       // }
  //     }

  //     setRecords(data);
  //   };

  //   fetchData().catch((e) => {
  //     console.error(e);
  //   });
  // }, []);

  // const downloadFile = ({ data, fileName, fileType }) => {
  //   // Create a blob with the data we want to download as a file
  //   const blob = new Blob([data], { type: fileType });
  //   // Create an anchor element and dispatch a click event on it
  //   // to trigger a download
  //   const a = document.createElement('a');
  //   a.download = fileName;
  //   a.href = window.URL.createObjectURL(blob);
  //   const clickEvt = new MouseEvent('click', {
  //     view: window,
  //     bubbles: true,
  //     cancelable: true,
  //   });
  //   a.dispatchEvent(clickEvt);
  //   a.remove();
  // };

  // const exportToJson = (e) => {
  //   e.preventDefault();
  //   downloadFile({
  //     data: JSON.stringify(records),
  //     fileName: `${fileName}.json`,
  //     fileType: 'text/json',
  //   });
  // };

  return (
    <AdminPage title='Users'>
      <p>
        This can be used for manually exporting activity for the current POC
        data set
      </p>
      {/* {!records && <p>Preparing...</p>}
      {records && (
        <Button
          style={{ width: 150 }}
          className='btn btn-primary'
          onClick={exportToJson}
        >
          Download as JSON
        </Button>
      )} */}
    </AdminPage>
  );
};

export default UserExport;
