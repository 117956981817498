/* eslint-disable react/display-name */
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import AdminCard from '../../../components/AdminCard';
import AdminPage from '../../../components/AdminPage';
import AdminRecord from '../../../components/AdminRecord';
import { AdminToastTimed } from '../../../components/AdminToasts';
import { uuidv4 } from '../../../utils/uuid';

const AdminGroupDetail = () => {
  const { id } = useParams<{ id: string }>();
  const location: any = useLocation();

  const getContent = (content: any) => {
    if (typeof content === 'object' && content !== null) {
      if (content.length > 0 && typeof content[0] !== 'object') {
        return content.join('; ');
      } else {
        // admin groups which is an array of objects
        return content.map((x) => x.name).join('; ');
      }
    } else {
      return typeof content === 'boolean' ? (content ? 'Yes' : 'No') : content;
    }
  };

  const itemTemplate = (data) => (
    <AdminCard title='Account'>
      <Table>
        <TableBody>
          {Object.entries(data).map(([key, value]: any) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell>{getContent(value)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </AdminCard>
  );

  return (
    <>
      {location.state?.detail === 'newAdmin' && (
        <AdminToastTimed
          message={'Admin successfully created'}
          trigger={location.state?.detail === 'newAdmin' ? uuidv4() : null}
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}
      <AdminPage
        title={`Admin Group Detail`}
        editAction={`/admins/group/${id}/edit`}
      >
        <AdminRecord url={`/admin/groups/${id}`} itemTemplate={itemTemplate} />
      </AdminPage>
    </>
  );
};

export default AdminGroupDetail;
