import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import { FieldProps } from '@rjsf/utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toastError } from '../../config/toast';
import AdminAssetLink from '../AdminAssetLink';
import AdminAssetViewer from '../AdminAssetViewer';
import { useBlueprintAdmin } from '../AdminProvider';

export const AdminAssetWidget = function (props: FieldProps) {
  const [assetInfo, setAssetInfo] = useState<any>();
  const { adminAssetApi, adminContentApi } = useBlueprintAdmin();
  const [collection, setCollection] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { collectionId, recordId } =
    useParams<{ collectionId: string; recordId: string }>();

  const setAsset = async (file) => {
    props.onChange('');

    if (adminAssetApi) {
      setIsLoading(true);
      await adminAssetApi
        .uploadAsset({
          file: file,
          entityType: collection.entity,
          entityId: recordId ? recordId : undefined,
        })
        .then(({ data }) => {
          props.onChange({
            id: data.id,
            key: data.key,
          });
          setIsLoading(false);
        })
        .catch(() =>
          toastError(
            `In the cloud it's dark, Upload failed, we missed the mark, Refresh and embark.`
          )
        );
    }
  };

  useEffect(() => {
    if (adminContentApi && collectionId) {
      adminContentApi
        .getCollection(collectionId)
        .then(({ data }) => {
          setCollection(data);
        })
        .catch((err) => console.error(err));
    }
  }, []);

  useEffect(() => {
    console.log(props.formData);
    if (adminAssetApi && props.formData && props.formData['id']) {
      adminAssetApi
        .getAssetById(props.formData['id'])
        .then(({ data }) => {
          setAssetInfo(data);
        })
        .catch((err) => console.error(err));
    }
  }, [props.formData]);

  const isViewableAsset = (asset) => {
    if (
      (asset && asset.mime.startsWith('image/')) ||
      asset.mime.startsWith('video/')
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Grid xs={12} container>
      <Grid item xs={12}>
        <Typography variant='subtitle2'>
          {props.schema.title ?? props.label ?? props.name}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <>
          {assetInfo && (
            <>
              {isViewableAsset(assetInfo) && (
                <AdminAssetViewer
                  id={props.formData.id}
                  assetType={
                    assetInfo.mime.startsWith('image/') ? 'image' : 'video'
                  }
                />
              )}
              {!isViewableAsset(assetInfo) && (
                <Grid
                  container
                  spacing={0}
                  style={{ minHeight: 100, backgroundColor: '#D3D3D3' }}
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Typography variant='caption'>
                    Asset Preview Unavailable
                  </Typography>
                  <AdminAssetLink id={assetInfo.id} caption={'Download'} />
                </Grid>
              )}
            </>
          )}
          {!props.formData && (
            <Grid
              container
              spacing={0}
              style={{ minHeight: 100, backgroundColor: '#D3D3D3' }}
              direction='column'
              alignItems='center'
              justifyContent='center'
            >
              <Typography variant='caption'>No Asset Selected</Typography>
            </Grid>
          )}
        </>
      </Grid>
      <Grid item xs={6} paddingLeft={10}>
        <Grid xs={12} container direction='column'>
          {isLoading && (
            <>
              <Typography variant='caption'>Upload in progress</Typography>
              <LinearProgress />
            </>
          )}
          {assetInfo && (
            <>
              <Typography variant='caption'>
                <strong>Asset ID:</strong> {assetInfo.id}
              </Typography>
              <Typography variant='caption'>
                <strong>Uploaded:</strong> {assetInfo.createdAt}
              </Typography>
              <Typography variant='caption'>
                <strong>Format:</strong> {assetInfo.mime}
              </Typography>
            </>
          )}
        </Grid>
        <Grid xs={12} paddingTop={2}>
          <Button variant='outlined' component='label'>
            Upload New Asset
            <input
              name='file'
              id='contained-button-file'
              type='file'
              hidden
              onChange={async (e: any) => {
                if (e?.target?.files) {
                  const file = e.target.files[0];
                  console.log(file);

                  await setAsset(file);
                }
              }}
            />
          </Button>
          {assetInfo && (
            <Button
              variant='contained'
              color='error'
              sx={{ marginLeft: 4 }}
              onClick={() => {
                props.onChange('');
                setAssetInfo(undefined);
              }}
            >
              Delete
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
