import AdminPage from '../../components/AdminPage';
import { Link } from 'react-router-dom';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { Avatar, Chip, Link as UiLink, TableCell } from '@mui/material';
import { AdminTableRow } from '../../components/AdminTable';

const UserIdentityList = () => {
  const identityTemplate = ({ id, idKey, idValue, users }, index) => (
    <AdminTableRow key={index}>
      <TableCell>{idKey}</TableCell>
      <TableCell>{idValue}</TableCell>
      <TableCell>
        {users && users.length > 0 && (
          <Chip
            color='primary'
            avatar={<Avatar>{users.length}</Avatar>}
            label='users attributed'
          />
        )}
        {users && users.length === 0 && (
          <Chip
            color='primary'
            avatar={<Avatar>{users.length}</Avatar>}
            label='users attributed'
          />
        )}
      </TableCell>
      <TableCell>
        <UiLink component={Link} key={id} to={`/identity/${id}`}>
          View
        </UiLink>
      </TableCell>
    </AdminTableRow>
  );

  return (
    <AdminPage title='Identities'>
      <AdminPaginatedList
        url='/admin/identities'
        columns={['identifier', 'value', 'attributed users', 'actions']}
        itemTemplate={identityTemplate}
      />
    </AdminPage>
  );
};

export default UserIdentityList;
