import { AccordionDetails } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  ActivitySettingForm,
  setActivitySubmit,
} from './beaconSettingComponents/activitySettingComponent';
import {
  ConfigurationSettingForm,
  setConfigurationSubmit,
} from './beaconSettingComponents/configurationComponent';
import {
  setStoreSubmit,
  StoreSettingForm,
} from './beaconSettingComponents/storeSettingComponent';
import {
  BeaconFormDefaultValues,
  BeaconFormFields,
  BeaconSettingAccordian,
} from './BeaconSettingConstants';

const defaultValues = BeaconFormDefaultValues;
const BeaconSettingForm = ({
  id,
  onFormChange,
  form_data,
}: BeaconSettingAccordian) => {
  const methods = useForm<BeaconFormFields>({ defaultValues: form_data });
  const { control, setValue, getValues } = methods;

  const onSubmit = () => {
    const updatedData = getValues();
    if (id === 'activity') {
      setActivitySubmit(form_data, updatedData);
    }
    if (id === 'configuration') {
      setConfigurationSubmit(form_data, updatedData);
    }
    if (id === 'store') {
      setStoreSubmit(form_data, updatedData);
    }
    onFormChange(form_data, id);
  };

  const FormPicker = () => {
    switch (id) {
      case 'activity':
        return ActivitySettingForm(control, setValue, onSubmit, form_data);
      case 'configuration':
        return ConfigurationSettingForm(control, setValue, onSubmit, form_data);
      case 'store':
        return StoreSettingForm(control, setValue, onSubmit, form_data);
      default:
        return (
          <>
            <p>This tracker currently has no customization settings</p>
          </>
        );
    }
  };

  return (
    <>
      <AccordionDetails>
        <FormPicker></FormPicker>
      </AccordionDetails>
    </>
  );
};

export default BeaconSettingForm;
