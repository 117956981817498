import {
  Breadcrumbs as MUIBreadcrumbs,
  Link as UiLink,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

export interface Breadcrumb {
  href?: string;
  label: string;
}

const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  return (
    <MUIBreadcrumbs aria-label='breadcrumb'>
      {breadcrumbs.map((item, index) =>
        item.href ? (
          <UiLink component={Link} key={index} color='inherit' to={item.href}>
            {item.label}
          </UiLink>
        ) : (
          <Typography key={index}>{item.label}</Typography>
        )
      )}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
