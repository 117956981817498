import { Grid, Typography } from '@mui/material';
import { AdminToolTip } from '../../../../../components/AdminToolTip';
import { FormInputText } from '../../form-components/FormInputText';
import { DefaultBeaconSettingOptions } from '../BeaconSettingConstants';
export const setConfigurationConfig = (savedData, name) => {
  const configurationSettings = savedData.configuration;
  const form_data_obj: any = {
    ...DefaultBeaconSettingOptions.find((element) => {
      return element.id === name;
    })?.form_data,
  };
  if (configurationSettings.dynamicConfigCacheDuration) {
    form_data_obj.dynamicConfigCacheDuration =
      configurationSettings.dynamicConfigCacheDuration;
  }
  return form_data_obj;
};

export const userConfigurationSetting = (form_data) => {
  if (form_data.dynamicConfigCacheDuration === '') {
    return null;
  } else {
    return { dynamicConfigCacheDuration: form_data.dynamicConfigCacheDuration };
  }
};

export const setConfigurationSubmit = (form_data, updatedData) => {
  form_data.dynamicConfigCacheDuration = updatedData.dynamicConfigCacheDuration;
};

export const ConfigurationSettingForm = (
  control,
  setValue,
  onSubmit,
  form_data
) => {
  return (
    <Grid container spacing={1}>
      <p style={{ marginTop: 0, marginBottom: 0 }}>
        Dynamic Configuration Cache Duration{' '}
        <AdminToolTip
          message={
            <Typography variant='caption'>
              placeholder text for what we want to display for info
            </Typography>
          }
        ></AdminToolTip>
      </p>
      <Grid item spacing={1} sm={12}>
        <FormInputText
          name='dynamicConfigCacheDuration'
          control={control}
          label='Duration in seconds'
          defaultValue={form_data.dynamicConfigCacheDuration}
          setValue={setValue}
          submitfunction={onSubmit}
          errorCheck={'integerCheck'}
          testId='configuration-input'
        />
      </Grid>
    </Grid>
  );
};
export {};
