import MonacoEditor from '@monaco-editor/react';
import { Link as UiLink } from '@mui/material';
import Container from '@mui/material/Container';
import TableCell from '@mui/material/TableCell';
import { useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import AdminModal from '../AdminModal';
import AdminPaginatedList from '../AdminPaginatedList';
import { AdminTableRow } from '../AdminTable';

const UserActivity = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState<any>();
  const [selectedActivity, setSelectedActivity] = useState<any>();

  const openModal = (data) => {
    setSelectedActivity(data);
    setIsModalOpen(true);
  };

  const closeModal = (data) => {
    setIsModalOpen(false);
  };

  const itemTemplate = (data, index) => (
    <AdminTableRow key={index}>
      <TableCell>{data.event}</TableCell>
      <TableCell>
        <Moment fromNow>{data.originalTimestamp}</Moment>
      </TableCell>
      <TableCell>
        <UiLink
          component={Link}
          to='#'
          color='primary'
          onClick={() => openModal(data)}
        >
          View
        </UiLink>
      </TableCell>
    </AdminTableRow>
  );

  const activityModalTitle = () => {
    if (selectedActivity) {
      return selectedActivity.id;
    } else {
      return 'Activity';
    }
  };

  const activityModalTemplate = () => (
    <>
      {selectedActivity && (
        <MonacoEditor
          theme='vs-dark'
          defaultLanguage='json'
          value={JSON.stringify(selectedActivity, null, 2)}
          height='100vh'
          width='100%'
        />
      )}
    </>
  );

  return (
    <>
      <Container
        style={{
          paddingLeft: 24,
          paddingBottom: 24,
          paddingTop: 0,
          paddingRight: 0,
        }}
      >
        <AdminPaginatedList
          exportEnabled={true}
          title='Recent Activity'
          url={`/admin/activity/user/${id}`}
          columns={['event', 'timestamp', 'actions']}
          itemTemplate={itemTemplate}
          pageSize={10}
          showCard={true}
          infiniteLoad={false}
        />
      </Container>
      <AdminModal
        title={activityModalTitle()}
        body={activityModalTemplate()}
        isOpen={isModalOpen}
        closeModal={closeModal}
        size='xl'
      />
    </>
  );
};

export default UserActivity;
