import { Avatar } from '@mui/material';
import { Buffer } from 'buffer';

const arrayBufferToBase64 = (buffer: Buffer) => {
  return Buffer.from(buffer).toString('base64');
};

const AdminAvatar = ({
  src,
  alt,
  size = 56,
}: {
  src: Buffer;
  alt: string;
  size?: number;
}) => {
  return (
    <Avatar
      src={`data:image/jpeg;charset=utf-8;base64,${arrayBufferToBase64(src)}`}
      alt={alt}
      sx={{ width: size, height: size }}
    />
  );
};

export default AdminAvatar;
