import { Grid } from '@mui/material';
import { useState } from 'react';
import DestinationSelectorCard from './DestinationSelectorCard';

const DestinationSelectorGrid = ({ beacon }: any) => {
  const [destinations, setDestinations] = useState(
    beacon.destinations.filter((item) => item.status === 'active')
  );

  return destinations.map((destination) => (
    <Grid style={{ paddingBottom: 10 }} key={destination.id}>
      <DestinationSelectorCard
        beaconKey={beacon.key}
        destinations={destinations}
        obj={destination}
        onChange={(items) => setDestinations(items)}
      >
        <p>Change Settings for Destination</p>
      </DestinationSelectorCard>
    </Grid>
  ));
};

export default DestinationSelectorGrid;
