import {
  Box,
  Grid,
  Link as UiLink,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import AdminAvatar from '../../components/AdminAvatar';
import AdminCard from '../../components/AdminCard';
import AdminRecord from '../../components/AdminRecord';
import {
  AdminTableHead,
  AdminTableHeadCell,
  AdminTableRow,
} from '../../components/AdminTable';
import { useBlueprintAdmin } from '../AdminProvider';
import UserActivity from './UserActivity';
import UserDetailForm from './UserDetailForm';
import UserDevices from './UserDevices';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  profileTable: {},
  profileCard: {
    paddingBottom: theme.spacing(2),
  },
  tableHeader: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[100],
  },
}));

const UserProfileTemplate = ({ id }) => {
  const { jwtToken, config } = useBlueprintAdmin();
  const classes = useStyles();

  const getValidFields = (user) => {
    const fields = Object.entries(user);
    const validFields: Array<any> = [];
    for (const field of fields) {
      if (
        field[0] !== 'token' &&
        field[0] !== 'parentUserId' &&
        field[0] !== 'updatedAt' &&
        field[0] !== 'avatar'
      ) {
        validFields.push(field);
      }
    }

    return validFields;
  };

  const accountTemplate = (title, user) => (
    <Grid className={classes.profileCard}>
      <AdminCard title={title}>
        {user && (
          <>
            {user.avatar && (
              <Box mb={5}>
                <AdminAvatar
                  src={user.avatar?.data}
                  alt={user.username}
                  size={128}
                />
              </Box>
            )}
            <Table className={classes.profileTable}>
              <TableBody>
                {getValidFields(user).map(([key, value]: any, i) => (
                  <AdminTableRow key={i}>
                    <AdminTableHeadCell className={classes.tableHeader}>
                      {key}
                    </AdminTableHeadCell>
                    <TableCell>{value}</TableCell>
                  </AdminTableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
        {!user && (
          <>
            <Skeleton variant='rectangular' height={370} width='100%' />
          </>
        )}
      </AdminCard>
    </Grid>
  );

  const identityTemplate = (title, user) => (
    <Grid
      style={{
        paddingLeft: 24,
        paddingBottom: 24,
        paddingTop: 0,
        paddingRight: 0,
      }}
    >
      <AdminCard title={title}>
        {user && user.identity && (
          <Table>
            <AdminTableHead>
              <TableRow>
                <AdminTableHeadCell>key</AdminTableHeadCell>
                <AdminTableHeadCell>id</AdminTableHeadCell>
                <AdminTableHeadCell>actions</AdminTableHeadCell>
              </TableRow>
            </AdminTableHead>
            <TableBody>
              <AdminTableRow key={0}>
                <TableCell>{user.identity.idKey}</TableCell>
                <TableCell>{user.identity.idValue}</TableCell>
                <TableCell>
                  <UiLink component={Link} to={`/identity/${user.identity.id}`}>
                    View
                  </UiLink>
                </TableCell>
              </AdminTableRow>
            </TableBody>
          </Table>
        )}
        {!user.identity && (
          <Skeleton variant='rectangular' height={110} width='100%' />
        )}
      </AdminCard>
    </Grid>
  );

  const traitTemplate = (title, profile) => (
    <AdminCard title='Traits'>
      <Grid className={classes.profileCard}>
        <UserDetailForm user={profile} id={id} title={title} />
      </Grid>
    </AdminCard>
  );

  const segmentTemplate = (title, segments) => (
    <>
      {segments && segments.length > 0 && (
        <Grid className={classes.profileCard}>
          <AdminCard title={title}>
            <Table>
              <TableBody>
                {segments.map(([key, value]: any) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AdminCard>
        </Grid>
      )}
    </>
  );

  const triggerTemplate = (title, triggers) => (
    <>
      <Grid
        className={classes.profileCard}
        style={{
          paddingLeft: 24,
          paddingBottom: 24,
          paddingTop: 0,
          paddingRight: 0,
        }}
      >
        <AdminCard title={title}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>trigger</TableCell>
                <TableCell>timestamp</TableCell>
              </TableRow>
            </TableHead>
            <tbody>
              {triggers.map((trigger: any) => (
                <AdminTableRow key={trigger.id}>
                  <TableCell>{trigger.trigger.name}</TableCell>
                  <TableCell>
                    <Moment fromNow>{trigger.createdAt}</Moment>
                  </TableCell>
                </AdminTableRow>
              ))}
            </tbody>
          </Table>
        </AdminCard>
      </Grid>
    </>
  );

  return (
    <>
      {id && (
        <Grid container>
          <Grid item sm={12} md={6}>
            <AdminRecord
              loadingTemplate={accountTemplate('Profile', undefined)}
              form={true}
              url={`/admin/users/${id}`}
              itemTemplate={accountTemplate.bind(null, 'Profile')}
            />
            <AdminRecord
              loadingTemplate={traitTemplate('Traits', undefined)}
              form={true}
              url={`/admin/users/${id}/profile`}
              itemTemplate={traitTemplate.bind(null, 'Traits')}
            />
            <UserDevices id={id} />
            <AdminRecord
              form={true}
              url={`/admin/users/${id}/segments`}
              itemTemplate={segmentTemplate.bind(null, 'Segments')}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            {/* <AdminRecord
              loadingTemplate={identityTemplate('Identity', {})}
              form={true}
              url={`/admin/users/${id}/identity`}
              itemTemplate={identityTemplate.bind(null, 'Identity')}
            /> */}
            <UserActivity id={id} />
            <AdminRecord
              form={true}
              url={`/admin/triggers/user/${id}/history`}
              itemTemplate={triggerTemplate.bind(null, 'Triggers')}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserProfileTemplate;
