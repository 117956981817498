import { uuidv4 } from '../../../../utils/uuid';

export const DefaultBeaconOptions = [
  {
    id: 'user',
    unique_id: uuidv4(),
    title: 'User',
    description:
      'The user tracker is critical for support anonymous, known, and authenticated experiences. By default, this tracker will provide support to tie activity to anonymous users, but can be configured to assume identity from other cookies or create its own 1st party cookie identity.',
    toolTip:
      "User tracker resolves a user’s identity. It is the most critical tracker and should always be selected if the client wishes to collect first-party data as it can fundamentally change an experience. By default a user's identity is established by seeding an anonymous token",
    enabled: true,
    disableToggle: true,
    form_data: {
      UserTrackerSetting: '',
      AssumeIdentityKey: '',
      AssumeIdentityType: '',
      SAUtextValue: '',
    },
  },
  {
    id: 'session',
    unique_id: uuidv4(),
    title: 'Session',
    description:
      'Recommended for debugging. The session tracker captures user sessions to connect activity for a specific user across singular usage. By default, this tracker will create a session after 30 minutes of inactivity.',
    toolTip:
      "A user's engagement with the experience starts with a session. This session has an ID and a timestamp that marks the beginning and end of the session. By default a session's duration is 30 minutes",
    enabled: false,
    form_data: {
      sessionDuration: '',
    },
  },
  {
    id: 'oneTrust',
    unique_id: uuidv4(),
    title: 'OneTrust',
    description:
      'OneTrust tracker collects consent opt-ins and opt-out events from the end user on a solution.',
    toolTip:
      "OneTrust tracker ties a user's consent groups (e.g. cookies settings) to a user's profile. As opt-in and opt-out occurs, this tracker dynamically alters the user's consent groups on their respective profile.",
    enabled: false,
    form_data: {
      mode: 'addTrait',
    },
  },
  {
    id: 'fbPixel',
    unique_id: uuidv4(),
    title: 'Facebook Pixel',
    enabled: false,
    description:
      'The facebook pixel tracker tracks all events that were sent to the Facebook pixel and routes them through the beacon.',
    toolTip:
      'Facebook Pixel tracker listens to a pre-configured mapping that was created initially for the Facebook Pixel (e.g. via a tag manager)',
    form_data: {
      fbPixelID: '',
      defaultSelected: 'defaultSettings',
    },
  },
  {
    id: 'dataLayer',
    unique_id: uuidv4(),
    title: 'Tag Manager',
    description:
      'The tag manager tracker will listen to Google Tag Manager or Adobe Tag Manager to connect events to feed into the beacon.',
    toolTip:
      'Tag Manager tracker will listen to Google Tag Manager or Adobe Tag Manager events and send them enriched and server-side',
    enabled: false,
    form_data: {
      customKey: '',
      ignoreEvents: [],
    },
  },
  {
    id: 'page',
    unique_id: uuidv4(),
    title: 'Page',
    description:
      'The page tracker will automatically track page load events to collect individual page views, along with its associated metadata.',
    toolTip:
      'Page tracker will automatically track page load events to collect individual page views',
    enabled: false,
    form_data: {
      pageAutoTrackDelay: null,
      pageMetaPreferenceEvent: null,
      pageMetaPreferenceEntity: null,
      pageMetaPreferenceEntityId: null,
      pageMetaPreferenceProperties: null,
      defaultSelected: 'defaultSettings',
    },
  },
  {
    id: 'form',
    unique_id: uuidv4(),
    title: 'Form',
    description:
      'The form tracker automatically tracks form submits to capture submitted data from users.',
    toolTip:
      'Form tracker automatically tracks form submits to capture submitted data from users.',
    enabled: false,
    form_data: {},
  },
  {
    id: 'click',
    unique_id: uuidv4(),
    title: 'Click',
    description:
      'The click tracker automatically tracks the click events on links and buttons.',
    toolTip:
      'Click tracker automatically tracks the click events on links and buttons.',
    enabled: false,
    form_data: {},
  },
];

export const alwaysEnabledTrackers = [
  {
    name: 'user',
    settings: {
      seedAnonymousUsers: true,
    },
  },
];

export const userBeaconAssumeIdentityDDOptions = [
  {
    label: 'Cookie',
    value: 'cookie',
  },
  {
    label: 'Window',
    value: 'window',
  },
];

export const oneTrustTrackerDDOptions = [
  {
    label: 'addTrait',
    value: 'addTrait',
    toolTip:
      "Automatically add active consent group as a trait on a user's profile",
  },
  {
    label: 'addTrait & assumeIdentity',
    value: 'assumeIdentity',
    toolTip:
      "add a trait on the user's profile but also act as the user's identity (NOTE: you will have to select 'assumeIdentity' on User Tracker as well and add dataSubjectId as the cookie to enable OneTrust as an identity)",
  },
];

export const userBeaconCheckboxOptions = [
  {
    label: 'Assume Identity',
    value: 'AssumeIdentity',
    toolTip:
      'This option is incredibly powerful when a solution has an existing identity system saved in the window or in a cookie. This option will save the queue of events that we collect until we detect the presence of a known identity cookie or window value, and then save that as an externalId and externalSource on a user’s profile.',
  },
  {
    label: 'Seed Anonymous User',
    value: 'seedAnonymousCookieless',
    toolTip:
      'This option should be used when a solution doesn’t have any existing identity or cookie-based system and we simply need to provide some organization around anonymous users and give their data a way to tie back to a physical computer.',
  },
  {
    label: 'Seed Anonymous User with a Cookie',
    value: 'seedAnonymousCookie',
    toolTip:
      'This option can be taken a step further from the seedAnonymous script, but also provides the ability to store a cookie in a 1st party manner directly on a client’s solution.',
  },
  {
    label: 'Wait for explicit opt in',
    value: 'optIn',
    toolTip:
      'For scenarios where we only want to track identity after optIn (perhaps listening to a OneTrust event). This approach will require custom JavaScript.',
  },
];

export const userBeaconAnonCookieDD = [
  {
    label: 'cookie',
    value: 'cookie',
  },
  {
    label: 'cookieless',
    value: 'cookieless',
  },
];

export const beaconadvancedDropDown = [
  {
    label: 'Default Settings',
    value: 'defaultSettings',
  },
  {
    label: 'Advanced Settings',
    value: 'advancedSettings',
  },
];

export interface TrackerFormFields {
  AssumeIdentityKey: string;
  AssumeIdentityType: string;
  UserTrackerSetting: string;
  SAUtextValue: string;
  mode: string;
  customKey: string;
  sessionDuration: string;
  advancedRadio: string;
  fbPixelID: string;
  pageAutoTrackDelay: string | null;
  pageMetaPreferenceEvent: string | null;
  pageMetaPreferenceEntity: string | null;
  pageMetaPreferenceEntityId: string | null;
  pageMetaPreferenceProperties: string | null;
  ignoreEvents: any;
}

export const trackerFormDefaultValues = {
  UserTrackerSetting: '',
  AssumeIdentityKey: '',
  AssumeIdentityType: '',
  mode: '',
  SAUtextValue: '',
  customKey: '',
  sessionDuration: '',
  advancedRadio: '',
  fbPixelID: '',
  ignoreEvents: [],
  pageAutoTrackDelay: null,
  pageMetaPreferenceEvent: null,
  pageMetaPreferenceEntity: null,
  pageMetaPreferenceEntityId: null,
  pageMetaPreferenceProperties: null,
};

export interface TrackerAccordian {
  title?: any;
  children?: any;
  selectable?: boolean;
  onSelect?: any;
  onFormChange?: any;
  id?: string;
  form_data?: any;
  enabled?: boolean;
  beacon?: any;
  showSettings?: boolean;
  onSelectSpecific?: any;
  disableToggle?: boolean;
}
